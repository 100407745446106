import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Page, Profile } from 'src/app/data-models/models';
import { environment } from 'src/environments/environment';

import { NavigatorService } from '../vendor/navigator.service';


export enum PlatformStates {
    PROFESSIONAL = 0,
    DEMO = 1
}

export enum PlatformViewStates {
    MOBILE = 0,
    DESKTOP = 1
}


const AUTH_TOKEN_KEY = 'authToken';


@Injectable({
    providedIn: 'root'
})

export class PlatformService {

    private platformState: number = PlatformStates.DEMO;
    private viewState: number = PlatformViewStates.DESKTOP;
    private isAiChatOpen: boolean = false;

    private permissionsSubject: Subject<Array<Page>> = new Subject<Array<Page>>();

    private userPermissions: Array<Page> = [];



    constructor(
        private http: HttpClient, 
        private navigatorService: NavigatorService, 
        public router: Router) { 

    }

    public init() {
        this.initViewState();
    }

    private initViewState(): void {

        this.viewState = PlatformViewStates.DESKTOP;

    }

    public getPlatformViewState(): number {
        return this.viewState;
    }

    public getPlatformState(): number {
        return this.platformState;
    }

    public getAiChatState(): boolean {
        return this.isAiChatOpen;
    }

    public setAiChatState(state: boolean): boolean {
        return this.isAiChatOpen = state;
    }

    public observeUserPermissions(): Observable<Array<Page>> {
        return this.permissionsSubject.asObservable();
    }

    public getPermissions(): Array<Page> {
        return this.userPermissions;
    }

    public refreshUserPermissions(): void {
        let self = this;
    
        this.navigatorService.getAsyncEmployeePagesPermissions(this.navigatorService.getProfileId()).then( (permissions: Array<Page>) => {
    
          if(permissions != null) {
            console.log("Returned User Permissions: ", permissions);
            self.userPermissions = permissions;

            self.permissionsSubject.next(self.userPermissions);
          }
    
        });
    }

    public setPlatformState(state: number): void {
        this.platformState = state;

        if(this.platformState == PlatformStates.DEMO) {
            console.log("Platform State Demo");
        } else {
            console.log("Platform State Professional");
        }
        
    }

    public versionChecker(version: string): boolean {
        let isGreaterThan: boolean = false;
        let versionBreakdown: Array<string> = version.split(".");
        let currentVersionBreakdown: Array<string> = environment.version.split(".");

        let segmentIndex: number = 0;

        for(let versionSegment of versionBreakdown) {

            let versionFormattedSegment: number = Number(versionSegment);
            let currentVersionFormattedSegment: number = Number(currentVersionBreakdown[segmentIndex]);

            if(currentVersionFormattedSegment > versionFormattedSegment) {
                console.log("Version 2 Features Authorized");
                isGreaterThan = true;
                break;
            }

            else if (currentVersionFormattedSegment == versionFormattedSegment) {
                continue;
            }

            else {
                isGreaterThan = false;
                break;
            }

            segmentIndex++; 
        }

        return isGreaterThan;
    }

    public internalToolsChecker(): boolean {

        let internal: boolean = false;
        let companyId: string = this.navigatorService.getCompanyId();

        if(companyId == environment.adminCompanyId) {
            internal = true;
        }

        return internal;

    }

    /**
     * Retrieves the authToken from local storage.
     * @returns {string | null} The authToken if it exists, otherwise null.
     */
    public getAuthToken(): string | null {
        return localStorage.getItem(AUTH_TOKEN_KEY);
    }
    
    /**
     * Sets the authToken in local storage.
     * @param token - The authToken to be saved.
     */
    public setAuthToken(token: string): void {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
    }

    /**
     * Deletes the authToken from local storage.
     */
    public deleteAuthToken(): void {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }

    private async addPasswordToProfile(email: string, password: string): Promise<string | null> {
        let token: string | null = null

        await this.http.post(`${environment.hostURL}/platform/api/upgradeProfile`, { email: email, password: password } ).toPromise().then( (data: { message: string, token: string | null, profile: Profile | null }) => {
            console.log("Profile Password Added", data );

            token = data?.token;

        });

        return token;
    }

    public async tokenLogin(): Promise<boolean> {
        let success: boolean = false;
        const token = this.getAuthToken();

        if (token) {
            await this.http.post(`${environment.hostURL}/platform/api/tokenLogin`, { token: token } ).toPromise().then( async (data: { message: string, token: string, profile: Profile | null }) => {
                console.log("Token Login", data );

                if(data?.token) {
                    this.setAuthToken(data?.token);
                
                    await this.navigatorService.login(data?.profile?.email, data?.profile?.password).then((isSuccess: boolean) => {

                        success = isSuccess;

                    });
                
                }

            });
        }

        return success;
    }

    private async hiveLogin(email: string, password: string): Promise<string | null> {
        let token: string | null = null

        await this.http.post(`${environment.hostURL}/platform/api/emailLogin`, { email: email, password: password } ).toPromise().then( (data: { message: string, success: boolean, token: string | null }) => {
            console.log("Hive Login", data );

            token = data?.token;

        });

        return token;
    }

    public async emailLogin(email: string, password: string): Promise<boolean> {
        let success: boolean = false;
    
        await this.navigatorService.login(email, password).then(async (isSuccess: boolean) => {

            success = isSuccess;

            if (success) {

                const profile: Profile = this.navigatorService.getCurrentProfile();
                let token: string | null = null;
                let self = this;

                setTimeout(async () => {

                    if (!profile?.version || profile?.version < 2 ) {
                        token = await self.addPasswordToProfile(email, password);
                    } else {
                        token = await self.hiveLogin(email, password);
                    }
    
                    if (token) {
                        self.setAuthToken(token);
                    }

                }, 20000);


            }

        });

        return success;
    }

    public async logout(): Promise<void> {

        this.deleteAuthToken();
        this.navigatorService.logout();

    }

}