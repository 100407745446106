import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFormNumberMask]'
})
export class FormNumberMaskDirective {
  private regex: RegExp = new RegExp(/^\d*$/); // Allows only numbers
  private previousValue: string = ''; // Tracks the previous valid value

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any): void {
    const input = this.el.nativeElement;

    // For input elements
    if (this.isInputElement(input)) {
      const value = input.value.replace(/,/g, ''); // Remove existing commas

      if (this.regex.test(value)) {
        this.previousValue = value; // Update the previous value
        input.value = this.formatWithCommas(value); // Format with commas
      } else {
        input.value = this.formatWithCommas(this.previousValue); // Revert to the previous value
      }
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: any): void {
    const element = this.el.nativeElement;

    // For non-input elements (like span or div)
    if (!this.isInputElement(element)) {
      const content = this.getTextContent().replace(/,/g, ''); // Get content without commas

      if (this.regex.test(content)) {
        this.previousValue = content; // Update the previous value
        this.setFormattedContent(this.formatWithCommas(content)); // Format with commas
      } else {
        this.setFormattedContent(this.formatWithCommas(this.previousValue)); // Revert to the previous value
      }
    }
  }

  private isInputElement(element: HTMLElement): boolean {
    return element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';
  }

  private formatWithCommas(value: string): string {
    if (!value) return '';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
  }

  private getTextContent(): string {
    return this.el.nativeElement.innerText || this.el.nativeElement.textContent || '';
  }

  private setFormattedContent(value: string): void {
    this.el.nativeElement.innerText = value; // Update the content for non-input elements
  }
}
