import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { keyTypesEnum, NavigatorService, workOrderTypesEnum } from 'src/app/services/vendor/navigator.service';
import { Column, CRMDeal, CRMPipe, DocumentedForm, FormDocument, FormDocumentExtAccess, FormElement, FormPage, FormSignatureRequest, LatLng, LinkedData, Row, WorkOrder, WorkOrderCustomField, WorkOrderService } from 'src/app/data-models/models';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { ThemeService } from 'ng2-charts';
import SignaturePad from 'signature_pad/src/signature_pad';
import { differenceInBusinessDays, formatISO9075 } from 'date-fns';

import { SelectContainerComponent } from 'ngx-drag-to-select';

import html2canvas from 'html2canvas';
import { group } from 'console';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
// import * as html2canvas from 'html2canvas';

export enum ElementTypes {
  TEXTAREA = 0,
  INPUT = 1,
  FILE_INPUT = 2,
  CHECKBOX = 3,
  SIGNATURE = 4,
  PARAGRAPH = 5,
  IMG = 6,
  VIDEO = 7,
  TABLE = 8,
  IMG_TABLE = 9,
  DATE = 10,
  CONTAINER = 11,
  MAP = 12,
  HR = 13,
  VR = 14,
  SIGNED = 15,

  // NEEDS TO BE INTEGRATED
  DROPDOWN_MENU = 16, // *


  COMPONENT_SERVICES_RENDERED = 30,
  COMPONENT_LABOR_RENDERED = 31,
  COMPONENT_PARTS_RENDERED = 32,
  COMPONENT_EQUIPMENT_RENDERED = 33,
  COMPONENT_CALENDAR = 34,

  // NEEDS TO BE INTEGRATED
  COMPONENT_PART_ATTACHER = 35, // **
  COMPONENT_FOLLOW_ON_WO = 36, // **
  COMPONENT_ASSIGN_STAFF = 37, // **


  ACTION_BTN_REPEATER = 60,
  ACTION_BTN_REQUEST_APPROVAL = 61,
  ACTION_BTN_SIGNATURE_REQUIRED = 62,
}

export enum DocumentAuditCategories {
  DOCUMENT_CREATED = 0,

  EMPLOYEE_VIEWED = 1,
  CUSTOMER_VIEWED = 2,
  SIGNER_VIEWED = 3,
  SUBCONTRACTOR_VIEWED = 4,

  EMPLOYEE_EDITED = 5,
  CUSTOMER_EDITED = 6,
  SIGNER_EDITED = 7,
  SUBCONTRACTOR_EDITED = 8,

  EMPLOYEE_SIGNED = 9,
  CUSTOMER_SIGNED = 10,
  SIGNER_SIGNED = 11,
  SUBCONTRACTOR_SIGNED = 12
}

export enum TabModes {
  VIEW = 0,
  EDIT = 1
}

export enum SettingsOptionKeys {
  TABLE_ROW = 0,
  TABLE_COLUMN = 1
}

export enum DatalinkTypes {
  DEAL = 0,
  WORK_ORDER = 1,
  INVOICE = 2
}

export enum TableTypes {
  FIELD = 0,
  IMG = 1,
  TEXT = 2,
}

export enum ElementResizeHandleCategories {
  TOP = 0,
  BOTTOM = 1,
  LEFT = 2,
  RIGHT = 3,
  TOP_LEFT = 4,
  TOP_RIGHT = 5,
  BOTTOM_LEFT = 6,
  BOTTOM_RIGHT = 7
}

export interface GroupFocus {
  id: string,
  index: number
}

@Component({
  selector: 'app-vendor-form',
  templateUrl: 'vendor-form.component.html',
  styleUrls: ['vendor-form.component.scss']
})
export class VendorFormComponent implements OnInit, AfterViewInit {
  @ViewChild('templateBuilderContainer') formContainer: ElementRef;
  @ViewChild('content') content: ElementRef;
  @ViewChild('contentBody') contentBody: ElementRef;
  @ViewChildren('signatureCanvas') signatureBlocks;
  @ViewChild('extAccessTable') extAccessTableRef: ElementRef;  

  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  @ViewChild(SelectContainerComponent) selectContainer: SelectContainerComponent;


  @Input() extId: string = null;
  @Input() documentId: string = null;
  @Input() templateId: string = null;
  @Input() companyId: string = null;
  @Input() documentCode: string = null;
  @Input() signatureCode: string = null;

  @Input() dealId: string = null;
  @Input() deal: CRMDeal = null;
  // @Input() workOrderId: string = "e70d2f148fee";

  @Input() workOrder: WorkOrder = null;
  @Input() workOrderId: string = null;
  @Input() invoiceId: string = null;
  @Input() dealMode: boolean = false;
  @Input() workOrderMode: boolean = false;
  @Input() builderMode: boolean = true;
  @Input() detailMode: boolean = false;
  @Input() woBuilderMode: boolean = false;
  @Input() zoomLevel: number = 100;

  @Output() documentSavedEmitter: EventEmitter<DocumentedForm> = new EventEmitter<DocumentedForm>();
  @Output() attachmentSavedEmitter: EventEmitter<DocumentedForm> = new EventEmitter<DocumentedForm>();

  public dragSelected: Array<any> = [];

  public signatureMode: boolean = false;
  public foreignViewer: boolean = true;
  public woVersionInvalid: boolean = false;

  public elementTypes: any = ElementTypes;
  public elementResizeHandleCategories: any = ElementResizeHandleCategories;
  public documentAuditCategories: any = DocumentAuditCategories;
  public tableTypes: any = TableTypes;
  public workOrderTableTypes = workOrderTypesEnum;
  public settingOptionKeys: any = SettingsOptionKeys;
  public datalinkTypes: any = DatalinkTypes;
  private keyTypes: any = keyTypesEnum;
  public tabModes: any = TabModes;

  public accessControlForm: FormGroup;

  public foreign_work_orders: Array<WorkOrder> = [];

  public zoomLevelTransform: string = "scale(1, 1)";

  public documentHeight: number = 1600;

  public page: FormDocument = {
    id: this.navigatorService.generateKey(),
    companyId: this.navigatorService.getCompanyId(),
    title: "New Document",
    backgroundColor: "#ffffff",
    margin: 0,
    type: 0,

    header: {
      id: this.navigatorService.generateKey(),
      active: false,
      height: 150,
      elements: [
        {
          id: "locked_header",
          type: this.elementTypes.CONTAINER,
          groupContainer: [],
          top: 0,
          left: 0,
          position: { x: 0, y: 0 },
          width: 0,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",

          elements: []
        }
      ]
    },

    footer: {
      id: "locked_footer",
      active: false,
      height: 150,
      elements: [
        {
          id: this.navigatorService.generateKey(),
          type: this.elementTypes.CONTAINER,
          groupContainer: [],
          top: 0,
          left: 0,
          position: { x: 0, y: 0 },
          width: null,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",

          elements: []
        }
      ]
    },

    pages: [
      {
        id: this.navigatorService.generateKey(),
        elements: []
      }
    ],

    activityAuditTrail: [],
    builderAuditTrail: [],

    audit: [],
    signVersions: [],

    extAccess: false,
    signatureAccessPriviledge: [],
    extAccessPriviledge: [],

    dateLastUpdated: new Date(),
    createdBy: null,
    created: new Date(),
  };

  public elementSettingOptions: any = {
    table: {
      colRow: this.settingOptionKeys.TABLE_ROW
    }
  }

  public signatureCanvas: any;
  public signaturePad: any;
  public elements: Array<FormElement> = [ ];
  public incomingElement: boolean = false;

  public imgs: Array<any> = [] // Stores Img ids and converts them to img URLs
  public gridView: boolean = false;
  public displayHeader: boolean = false;
  public displayFooter: boolean = false;
  public expandDeletePanel: boolean = false;
  public pageMenuExpanded: boolean = false;
  public saveAsPanelExpanded: boolean = false;
  public subActionMenuExpanded: boolean = false;
  public detailModeExpanded: boolean = false;
  public tabMode: number = this.tabModes.VIEW;
  public selectionAlignMenuExpanded: boolean = false;

  public editAccessPriviledge: FormDocumentExtAccess = null;
  public accessPanel: boolean = false;

  public pageFocus: number = 0;
  public elementConnections: Array<string> = [];
  public elementFocus: FormElement = null;
  public elementGroupFocus: Array<GroupFocus> = [];
  public focusElementIndex: number = null;
  public copiedElement: FormElement = null;
  public elementSettingsFocus: string = null;
  public elementDatalinkFocus: string = null;
  public elementGroup: FormElement = null;

  public dataLookupKeys: Array<LinkedData> = [];
  public customWorkOrderFields: Array<WorkOrderCustomField> = [];

  handleDragStart: boolean = false;
  handleDragStop: boolean = true;
  handleCategory: number = null;

  public editColumnTitleMode: boolean = false;
  public editTitleMode: boolean = false;
  public title: string = "New Document";
  public backgroundColor: string = "";
  public paragraphContent: any = null;

  public headerHeight: number = 0;
  public footerHeight: number = 0;

  public xsmallContainer: boolean = false;
  public smallContainer: boolean = false;
  public mediumContainer: boolean = false;
  public largeContainer: boolean = false;

  @HostListener('window:resize')
  public onWindowResize():void {
    (this.formContainer.nativeElement.clientWidth > 1500) ? this.largeContainer = true : this.largeContainer = false; 
    (this.formContainer.nativeElement.clientWidth < 1500 && this.formContainer.nativeElement.clientWidth > 1200) ? this.mediumContainer = true : this.mediumContainer = false; 
    (this.formContainer.nativeElement.clientWidth < 1200 && this.formContainer.nativeElement.clientWidth > 480) ? this.smallContainer = true : this.smallContainer = false; 
    (this.formContainer.nativeElement.clientWidth < 480) ? this.xsmallContainer = true : this.xsmallContainer = false; 
  }

  constructor(
    public formBuilder: FormBuilder, 
    private imageHandler: ImageHandlerService,
    public snackBar: MatSnackBar, 
    private navigatorService: NavigatorService,
    private twilioHandler: TwilioHandlerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder) { 

  }

  ngOnInit() {

    console.log("Work Order Data: ", this.workOrder);

    this.accessControlForm = this.fb.group({
      email: [ { value: '', disabled: false }, Validators.compose([Validators.required, emailValidator])],
      phone: [ { value: '', disabled: false }, Validators.compose([Validators.required, Validators.minLength(10)])],
      expireDate: [ { value: new Date(), disabled: true }, Validators.compose([Validators.required, Validators.minLength(3)])]
    });

    console.log("Template: ", this.templateId);

    if(this.templateId != undefined && this.templateId != null) {
      this.initializeTemplate();
    } else {
      this.initializeDocument();
    }

    this.onZoomSliderChange(this.zoomLevel);
  }

  ngAfterViewInit(): void {
    this.initializeFilterSize();
    this.onZoomSliderChange(this.zoomLevel);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.activatedRoute.params.subscribe(params => { 

      if(this.documentId == undefined || this.documentId == null) {
        this.documentId = params['template_id']; 
      }

      console.log("Forms Document ID: ", this.documentId);

      if(this.deal != undefined && this.deal != null) {
        this.dealMode = true;
      }

      if(this.workOrder != undefined && this.workOrder != null) {
        this.workOrderMode = true;
      }

      this.initializeDocument();

    });

    console.log("Work Order Data: ", this.workOrder);

    this.initializeFilterSize();
    this.onZoomSliderChange(this.zoomLevel);
  } 

  private initializeTemplate(): void {

    this.foreignViewer = false;
    this.companyId = this.navigatorService.getCompanyId(); 
    this.getCustomWorkOrderFields();
    this.documentCode = "locked_user_granted"

    if(this.templateId != undefined && this.templateId != null) {

      this.navigatorService.getAsyncTemplate(this.templateId).then( (doc: FormDocument) => {

        // doc = {
        //   "id": "form-001",
        //   "companyId": "company-001",
        //   "title": "Product Information Form",
        //   "status": 1,
        //   "formSection": {
        //     "id": 1,
        //     "title": "Product Details",
        //     "documents": [],
        //     "dailyCount": [],
        //     "background": "#FFFFFF",
        //     "color": "#000000",
        //     "totalValue": 0
        //   },
        //   "backgroundColor": "#FFFFFF",
        //   "margin": 2,
        //   "type": 1,
        //   "header": {
        //     "id": "header-001",
        //     "active": true,
        //     "height": 10,
        //     "elements": []
        //   },
        //   "footer": {
        //     "id": "footer-001",
        //     "active": true,
        //     "height": 5,
        //     "elements": []
        //   },
        //   "pages": [
        //     {
        //       "id": "page-1",
        //       "title": "Product Information",
        //       "elements": [
        //         {
        //           "id": "element-1",
        //           "type": 10,
        //           "top": 5,
        //           "left": 5,
        //           "width": 30,
        //           "height": 5,
        //           "zIndex": 1,
        //           "borderRadius": 2,
        //           "backgroundTransparent": true,
        //           "backgroundColor": "",
        //           "color": "#000000",
        //           "position": {
        //             "x": 5,
        //             "y": 5
        //           },
        //           "title": "Date",
        //           "placeholder": "Select a date",
        //           "value": "",
        //           "elements": []
        //         },
        //         {
        //           "id": "element-2",
        //           "type": 1,
        //           "top": 15,
        //           "left": 5,
        //           "width": 30,
        //           "height": 5,
        //           "zIndex": 1,
        //           "borderRadius": 2,
        //           "backgroundTransparent": true,
        //           "backgroundColor": "",
        //           "color": "#000000",
        //           "position": {
        //             "x": 5,
        //             "y": 15
        //           },
        //           "title": "Serial Number",
        //           "placeholder": "Enter serial number",
        //           "value": "",
        //           "elements": []
        //         },
        //         {
        //           "id": "element-3",
        //           "type": 1,
        //           "top": 25,
        //           "left": 5,
        //           "width": 30,
        //           "height": 5,
        //           "zIndex": 1,
        //           "borderRadius": 2,
        //           "backgroundTransparent": true,
        //           "backgroundColor": "",
        //           "color": "#000000",
        //           "position": {
        //             "x": 5,
        //             "y": 25
        //           },
        //           "title": "Model Number",
        //           "placeholder": "Enter model number",
        //           "value": "",
        //           "elements": []
        //         }
        //       ]
        //     }
        //   ],
        //   "activityAuditTrail": [],
        //   "builderAuditTrail": [],
        //   "audit": [],
        //   "signVersions": [],
        //   "extAccess": false,
        //   "signatureAccessPriviledge": [],
        //   "extAccessPriviledge": [],
        //   "dateLastUpdated": "2023-10-05T00:00:00Z",
        //   "createdBy": "admin",
        //   "created": "2023-10-05T00:00:00Z"
        // };

        console.log("User Find: ", doc);


        if(doc != undefined && doc != null) {
          this.page = doc;
          this.elements = this.page.pages[0].elements;

          this.scanElements();

          if(this.workOrder != undefined && this.workOrder != null) {

            this.populateWorkOrder();

          }

          if(this.deal != undefined && this.deal != null) {

            this.populateDeal();

          }

        }

      });

    }

  }

  private async initializeDocument(): Promise<void> {

    if(this.signatureCode != undefined && this.signatureCode != null) {
      this.signatureMode = true;
      this.builderMode = false;
    }

    if(this.navigatorService.getProfileId() == undefined || this.navigatorService.getProfileId() == null || this.navigatorService.getProfileId().length == 0) {
      this.foreignViewer = true;
      this.builderMode = false;

      if(this.signatureMode) {

        this.navigatorService.getSignDocumentAsForeign(this.companyId, this.documentId, this.signatureCode).then( (doc: FormDocument) => {
          console.log("Foreign Find: ", doc);
  
          if(doc != undefined && doc != null) {
            this.page = doc;
            this.elements = this.page.pages[0].elements;
  
            this.scanElements();

            this.navigatorService.getGeoLocationAsForeign().then( (geo: LatLng) => {

              this.page.audit.unshift({
                id: this.navigatorService.generateKey(),
                tender: this.signatureCode,
                date: new Date(),
                type: this.documentAuditCategories.SIGNER_VIEWED,
              
                ipData: this.navigatorService.getIP(),
                geo: geo,
              });
        
              this.navigatorService.updateSignDocumentAsForeign(this.page);
            });

          }
  
        });

      } else {

        this.navigatorService.getDocumentAsForeign(this.companyId, this.documentId, this.documentCode).then( (doc: FormDocument) => {
          console.log("Foreign Find: ", doc);

          if(doc != undefined && doc != null) {
            this.page = doc;
            this.elements = this.page.pages[0].elements;

            this.scanElements();

            if(this.workOrderId != undefined && this.workOrderId != null) {

              // this.populateWorkOrderAsForeign();

            }

          }

        });

      }

      this.getCustomWorkOrderFieldsAsForeign(this.companyId);
    } else {
      this.foreignViewer = false;
      this.companyId = this.navigatorService.getCompanyId(); 
      this.getCustomWorkOrderFields();
      this.documentCode = "locked_user_granted"

      if(this.documentId != undefined && this.documentId != null) {

        if(this.documentId == 'default_wo_version') {

          this.getDefaultWorkOrderVersion().then( (version: FormDocument) => {

            if(version != undefined && version != null) {
              this.page = version;
              this.elements = this.page.pages[0].elements;

              this.scanElements();

              if(this.workOrder != undefined && this.workOrder != null) {

                this.populateWorkOrder();

              }
            } else {
              this.woVersionInvalid = true;
            }

          });

        } else {

          this.navigatorService.getAsyncDocument(this.documentId).then( (doc: FormDocument) => {
            // doc = {
            //   "id": "form_001",
            //   "companyId": "company_123",
            //   "title": "Product Information Form 1.0.1",
            //   "status": 1,
            //   "formSection": {
            //     "id": 1,
            //     "title": "Product Details",
            //     "documents": [],
            //     "dailyCount": [],
            //     "background": "#ffffff",
            //     "color": "#000000",
            //     "totalValue": 0
            //   },
            //   "backgroundColor": "#ffffff",
            //   "margin": 0,
            //   "type": 0,
            //   "header": {
            //     "id": "header_001",
            //     "active": false,
            //     "height": 0,
            //     "elements": []
            //   },
            //   "footer": {
            //     "id": "footer_001",
            //     "active": false,
            //     "height": 0,
            //     "elements": []
            //   },
            //   "pages": [
            //     {
            //       "id": "page_001",
            //       "title": "Product Information",
            //       "elements": [
            //         {
            //           "id": "element_001",
            //           "type": 10,
            //           "top": 0,
            //           "left": 0,
            //           "width": 200,
            //           "height": 50,
            //           "zIndex": 0,
            //           "borderRadius": 0,
            //           "backgroundTransparent": true,
            //           "backgroundColor": "#ffffff",
            //           "color": "#000000",
            //           "position": {
            //             "x": 10,
            //             "y": 10
            //           },
            //           "groupContainer": [],
            //           "datalinkType": 0,
            //           "datalink": {},
            //           "dropdownOptions": [],
            //           "value": "",
            //           "title": "Date",
            //           "placeholder": "Enter date",
            //           "elements": []
            //         },
            //         {
            //           "id": "element_002",
            //           "type": 1,
            //           "top": 0,
            //           "left": 0,
            //           "width": 200,
            //           "height": 50,
            //           "zIndex": 0,
            //           "borderRadius": 0,
            //           "backgroundTransparent": true,
            //           "backgroundColor": "#ffffff",
            //           "color": "#000000",
            //           "position": {
            //             "x": 10,
            //             "y": 20
            //           },
            //           "groupContainer": [],
            //           "datalinkType": 0,
            //           "datalink": {},
            //           "dropdownOptions": [],
            //           "value": "",
            //           "title": "Serial Number",
            //           "placeholder": "Enter serial number",
            //           "elements": []
            //         },
            //         {
            //           "id": "element_003",
            //           "type": 1,
            //           "top": 0,
            //           "left": 0,
            //           "width": 200,
            //           "height": 50,
            //           "zIndex": 0,
            //           "borderRadius": 0,
            //           "backgroundTransparent": true,
            //           "backgroundColor": "#ffffff",
            //           "color": "#000000",
            //           "position": {
            //             "x": 10,
            //             "y": 30
            //           },
            //           "groupContainer": [],
            //           "datalinkType": 0,
            //           "datalink": {},
            //           "dropdownOptions": [],
            //           "value": "",
            //           "title": "Model Number",
            //           "placeholder": "Enter model number",
            //           "elements": []
            //         }
            //       ]
            //     }
            //   ],
            //   "activityAuditTrail": [],
            //   "builderAuditTrail": [],
            //   "audit": [],
            //   "signVersions": [],
            //   "extAccess": false,
            //   "signatureAccessPriviledge": [],
            //   "extAccessPriviledge": [],
            //   "dateLastUpdated": "2023-10-01T00:00:00.000Z",
            //   "createdBy": "user_001",
            //   "created": "2023-10-01T00:00:00.000Z"
            // };
            
            console.log("User Find: ", doc);

            if(doc != undefined && doc != null) {
              this.page = doc;

              if(this.page.pages.length > 0) {

                this.elements = this.page.pages[0].elements;

                this.scanElements();

                if(this.workOrder != undefined && this.workOrder != null) {

                  this.populateWorkOrder();

                }

              }

            }

          });

        }

      }

    }
  }

  private initializeFilterSize(): void {

    if(this.formContainer != undefined && this.formContainer != null) {
      (this.formContainer.nativeElement.clientWidth > 1500) ? this.largeContainer = true : this.largeContainer = false; 
      (this.formContainer.nativeElement.clientWidth < 1500 && this.formContainer.nativeElement.clientWidth > 1200) ? this.mediumContainer = true : this.mediumContainer = false; 
      (this.formContainer.nativeElement.clientWidth < 1200 && this.formContainer.nativeElement.clientWidth > 480) ? this.smallContainer = true : this.smallContainer = false; 
      (this.formContainer.nativeElement.clientWidth < 480) ? this.xsmallContainer = true : this.xsmallContainer = false; 
    }

  }

  private async getDefaultWorkOrderVersion(): Promise<FormDocument> {
    let version: FormDocument = null;

    await this.navigatorService.getAsyncActiveWorkOrderVersion().then( (doc: FormDocument) => {
      version = doc;
    });

    return version;
  }

  private populateLinkedDataForm(): void {

    for(let element of this.elements) {

      if(element.datalinkType != undefined && element.datalinkType != null) {
        
        switch(element.datalinkType) {

          case this.datalinkTypes.DEAL:
            break;
  
          case this.datalinkTypes.WORK_ORDER:

            if(this.workOrder == undefined || this.workOrder == null) {
              return;
            }

            if(element.datalink.data == "locked_assignedStaff") {
              let data: any = this.getLinkedWorkOrderData(element.datalink);

              element.assignedStaff = data.staff;
              element.assignedTeams = data.teams;

            } else {
              element.value = this.getLinkedWorkOrderData(element.datalink);
            }

            break;
  
          case this.datalinkTypes.INVOICE:
            break;
  
        }

      }

    }

  }

  private populateDeal(): void {

    this.dealMode = true;

    this.navigatorService.getAsyncCRMDeal(this.deal.id).then( (deal: CRMDeal) => {

      if(deal != undefined && deal != null) {
        this.deal = deal;

        this.populateLinkedDataForm();

      }

    });

  }

  private populateWorkOrder(): void {

    this.workOrderMode = true;

    this.navigatorService.getWorkOrder(this.workOrder.workOrderId).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {
        this.workOrder = workOrder;

        this.populateLinkedDataForm();

      }

    });

  }

  private populateWorkOrderAsForeign(): void {

    this.navigatorService.getWorkOrderAsForeign(this.workOrderId, this.companyId).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {
        this.workOrder = workOrder;

        this.populateLinkedDataForm();

      }

    });

  }

  public onZoomSliderChange(zoomLvl: number): void {

    if(zoomLvl == undefined || zoomLvl == null) {
      return;
    }

    let zoomLevelFraction = zoomLvl / 100;

    this.zoomLevel = zoomLvl;
    this.zoomLevelTransform = "scale(" + zoomLevelFraction + ", " + zoomLevelFraction + ")";
  }

  public download(): void {

    html2canvas(this.content.nativeElement).then(canvas => {
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.page.title;
      this.downloadLink.nativeElement.click();
    });

  }

  scanElements(elements?: Array<FormElement>): void {

    let obtainImgs: boolean = false;

    if(elements == undefined || elements == null) {
      elements = this.elements;
      this.imgs = [];
      obtainImgs = true;
    }

    for(let element of elements) {

      switch(element.type) {

        case this.elementTypes.TEXTAREA:
          break;
        
        case this.elementTypes.INPUT:
          break;
  
        case this.elementTypes.FILE_INPUT:
          break;
  
        case this.elementTypes.DROPDOWN_MENU:
          break;

        case this.elementTypes.CHECKBOX:
          break;
  
        case this.elementTypes.SIGNATURE:
          break;
  
        case this.elementTypes.PARAGRAPH:
          break;
  
        case this.elementTypes.IMG:
          this.imgs.push(element.img);
          break;
  
        case this.elementTypes.VIDEO:
          break;
  
        case this.elementTypes.TABLE:
          break;
  
        case this.elementTypes.DATE:
          break;
  
        case this.elementTypes.CONTAINER:
          this.scanElements(element.elements);
          break;
  
        case this.elementTypes.MAP:
          break;

        case this.elementTypes.HR:
          break;

        case this.elementTypes.VR:
          break;

        case this.elementTypes.COMPONENT_SERVICES_RENDERED:
          break;

        case this.elementTypes.COMPONENT_LABOR_RENDERED:
          break;

        case this.elementTypes.COMPONENT_PARTS_RENDERED:
          break;

        case this.elementTypes.COMPONENT_EQUIPMENT_RENDERED:
          break;

        case this.elementTypes.COMPONENT_CALENDAR:
          break;

        case this.elementTypes.COMPONENT_PART_ATTACHER:
          break;

        case this.elementTypes.COMPONENT_FOLLOW_ON_WO:
          break;

        case this.elementTypes.COMPONENT_ASSIGN_STAFF:
          break;
  
      }

    }

    if(obtainImgs) {
      this.getAllImgs();
    }

  }

  public changeElementFocus(id: string, type?: number, element?: FormElement): void {
    this.elementFocus = element;

    this.handleDragStart = false;

    if(type != undefined && type == this.elementTypes.PARAGRAPH) {
      this.paragraphContent = element.value;
    }


    if(element.datalinkType != undefined && element.datalinkType != null) {
      switch(element.datalinkType) {

        case this.datalinkTypes.DEAL:
          break;

        case this.datalinkTypes.WORK_ORDER:
          this.dataLookupKeys = this.linkedDataKeyLookup();
          break;

        case this.datalinkTypes.INVOICE:
          break;

      }
    }

  }

  public openDocument(docId: string): void {
    this.router.navigate(['/']);
  }

  public editFocusElementText(text: string): void {
    this.elementFocus.value;
  }

  public pageSelect(pageNumber: number, skipCache: boolean = false): void {
    
      if(!skipCache) {
        this.cachePage();
      }

      this.pageFocus = pageNumber;
      this.changePageElementFocus();

  }

  public pageDelete(pageNumber: number): void {

    if(this.page.pages.length > 1) {
      this.page.pages.splice(pageNumber, 1);

      if(pageNumber == 0) {
        this.pageSelect(pageNumber, true);
      } 

      else if(pageNumber > 0 && pageNumber < this.page.pages.length - 1) {
        this.pageSelect(pageNumber--);
      } 

      else {
        this.pageSelect(this.page.pages.length - 1, true);
      }

    }

  }

  public pageAdd(): void {

    this.page.pages.push({
      id: this.navigatorService.generateKey(),
      title: "New Tab",
      elements: []
    });

    this.pageSelect(this.page.pages.length - 1);

  }

  public nextPage(): void {
    
    if(this.pageFocus < this.page.pages.length - 1) {
      this.cachePage();
      this.pageFocus++;
      this.changePageElementFocus();
    }

  }

  public previousPage(cache?: boolean): void {
    
    if(this.pageFocus > 0) {

      if(cache) {
        this.cachePage();
      }

      this.pageFocus--;
      this.changePageElementFocus();
    }

  }

  public cachePage(): void {

    if(this.page.pages[this.pageFocus] != undefined && this.page.pages[this.pageFocus] != null) {
      this.page.pages[this.pageFocus].elements = this.elements;
    }

  }

  public changeSettingColRow(key: number): void {
    this.elementSettingOptions.table.colRow = key;
  }

  public changePageElementFocus(): void {
    this.elements = [...this.page.pages[this.pageFocus].elements];
    this.populateLinkedDataForm();
  }


  // public calculateElementGroupArray(id: string, type?: number, elementIndex?: number): void {
    
  //   for(let groupFocus of this.elementGroupFocus) {

  //     let groupIndex: number = this.changeElementGroupFocus.findIndex( (goup: any) => { return group.id == })

  //   }

  // }

  public shiftElements(): void {

    let bodyHeight: number = this.contentBody.nativeElement.clientHeight;
    let pageIndex: number = 0;

    for(let page of this.page.pages) {

      for(let element of page.elements) {

        // Currently if an element is within a group they can be moved freely and will not trigger page transfers
        if(element.groupContainer == null || element.groupContainer == null || element.groupContainer.length == 0) {

          let elementEnd: number = element.position.y + element.height + element.top;
          let elementStart: number = element.position.y + element.top;

          if(this.page.header.active) {
            elementEnd -= this.page.header.height;
          }
          
          if(elementEnd > bodyHeight) {
            console.log("Shift To Next Page: ", element);

            let shifted: boolean = this.shiftPageElementsDown(element, this.page.pages[pageIndex + 1]);
            let elementIndex: number = page.elements.findIndex( (el: FormElement) => { return el.id == element.id });

            if(elementIndex > -1 && shifted) {
              page.elements.splice(elementIndex, 1);

              if(this.page.pages[this.pageFocus].id == page.id) {
                this.elements = [...page.elements];
              }
            }
          }

          else if(elementStart < this.headerHeight) {
            console.log("Shift To Previous Page: ", element);

            let shifted: boolean = this.shiftPageElementsUp(element, this.page.pages[pageIndex - 1]);
            let elementIndex: number = page.elements.findIndex( (el: FormElement) => { return el.id == element.id });

            if(elementIndex > -1 && shifted) {
              page.elements.splice(elementIndex, 1);

              if(this.page.pages[this.pageFocus].id == page.id) {
                this.elements = [...page.elements];
              }

              if(this.page.pages[this.pageFocus].elements.length == 0) {
                this.page.pages.splice(this.pageFocus, 1);
                this.previousPage(false);
              }

            }
          }

        }

      }

      pageIndex++;
    }


    // Hack to make all elemnts update with new position
    let tmpSelection = [...this.dragSelected];
    let tmp = [...this.elements];
    let self = this;

    this.elements = [];

    setTimeout(function() { 
      self.elements = [...tmp]; 
      self.reinitializeDragSelectedItems(tmpSelection);
    }, 10);

    setTimeout(function() { 
      self.registerListeners();
      }, 50);
  }

  private shiftPageElementsUp(shiftElement: FormElement, page: FormPage): boolean {
    let shiftedComplete: boolean = false;

    if(page == undefined || page == null) {
      shiftElement.top = 0;
      shiftElement.position.y = this.headerHeight;

      return shiftedComplete;
    }

    if(shiftElement == undefined || shiftElement == null) {
      return shiftedComplete;
    } else {
      shiftElement.top = 0;
      shiftElement.position.y = this.contentBody.nativeElement.clientHeight - shiftElement.height + this.headerHeight;
    }

    page.elements.unshift(JSON.parse(JSON.stringify(shiftElement))); // Needing a deep copy

    shiftedComplete = true;
    return shiftedComplete

  }

  private shiftPageElementsDown(shiftElement: FormElement, page: FormPage): boolean {
    let shiftedComplete: boolean = false;

    if(shiftElement == undefined || shiftElement == null) {
      return shiftedComplete;
    } else {
      shiftElement.top = 0;
      shiftElement.position.y = this.headerHeight;
    }

    if(page == undefined || page == null) {
      this.page.pages.push({
        id: this.navigatorService.generateKey(),
        elements: []
      });

      page = this.page.pages[this.page.pages.length - 1];
    }

    page.elements.unshift(JSON.parse(JSON.stringify(shiftElement))); // Needing a deep copy

    shiftedComplete = true;
    return shiftedComplete
  }

  private growElement(growingElement: FormElement, growth: number): void {

    let growingElementEnd: number = growingElement.position.y + growingElement.height + growingElement.top;
    let growingElementStart: number = growingElement.position.y + growingElement.top;

    for(let pageIndex: number = this.pageFocus; pageIndex < this.page.pages.length; pageIndex++) {

      for(let element of this.page.pages[pageIndex].elements) {

        let elementEnd: number = element.position.y + element.height + element.top;
        let elementStart: number = element.position.y + element.top;

        if(elementStart > growingElementEnd) {

          element.position.y += growth;

        }


      }

    }



    // Hack to make all elemnts update with new postiion
    let tmp = [...this.elements];
    let self = this;

    this.elements = [];

    setTimeout(function() { 
      self.elements = [...tmp]; 
    }, 10);

    setTimeout(function() { 
      self.registerListeners();
      }, 50);
}

  private shrinkElement(shrinkingElement: FormElement, shrink: number): void {

    let shrinkingElementEnd: number = shrinkingElement.position.y + shrinkingElement.height + shrinkingElement.top;
    let shrinkingElementStart: number = shrinkingElement.position.y + shrinkingElement.top;

    for(let pageIndex: number = this.pageFocus; pageIndex < this.page.pages.length; pageIndex++) {

      for(let element of this.page.pages[pageIndex].elements) {

        let elementEnd: number = element.position.y + element.height + element.top;
        let elementStart: number = element.position.y + element.top;

        if(elementStart > shrinkingElementEnd) {

          element.position.y -= shrink;

        }


      }

    }



     // Hack to make all elemnts update with new postiion
     let tmp = [...this.elements];
     let self = this;
 
     this.elements = [];
 
     setTimeout(function() { 
       self.elements = [...tmp]; 
       self.registerListeners();
      }, 10);
    
  }

  private registerListeners(): void {
    let signatureCounter: number = 0;

    for(let element of this.elements) {

      switch(element.type) {

        case this.elementTypes.SIGNATURE:
          this.initializeSignaturePad(element, signatureCounter);
          signatureCounter++;
          break;

        case this.elementTypes.SIGNED:
          this.initializeSignaturePad(element, signatureCounter);
          signatureCounter++;
          break;

      }

    }

  }

  public serviceTrackerChanged(event: Array<WorkOrderService>, element: FormElement): void {
    this.elementFocus = element;

    if(this.elementFocus.services == undefined || this.elementFocus.services == null) {
      this.elementFocus.services = [];
    }

    // Shink Page Elements
    if(this.elementFocus.services.length > event.length) {
      this.shrinkElement(this.elementFocus, 64);
    }

    // Grow Page Elements
    else if(this.elementFocus.services.length < event.length) {
      this.growElement(this.elementFocus, 64);
    }

    this.elementFocus.services = [...event];

  }

  public quillModules = {

    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link', 'image', 'video']                         // link and image, video
    ]

  };



  public elementsLeftAlign(groupedAlign: boolean = false): void {

    if(this.dragSelected.length == 0) {
      for(let element of this.elements) {
        element.position.x = 0 + this.page.margin;
        element.left = 0;
      }
    } else {

      let farthestLeft: number = null;

      for(let element of this.dragSelected) {

        if(farthestLeft == null || element.position.x < farthestLeft) {
          farthestLeft = element.position.x;
        }

      }

      let moveLeftCalc: number = 0;

      if(!groupedAlign) {
        moveLeftCalc = farthestLeft - (0 + this.page.margin);
      }

      for(let element of this.dragSelected) {

        if(groupedAlign) {
          let elementFarthestLeft: number = element.position.x;
          let diff: number = elementFarthestLeft - farthestLeft;
          element.position.x -= diff;
        } else {
          element.position.x -= moveLeftCalc;
        }

        element.left = 0;
      }
    }


    // Hack to make all elemnts update with new postiion
    let tmpSelection = [...this.dragSelected];
    let tmp = [...this.elements];
    let self = this;

    this.elements = [];

    setTimeout(function() { 
      self.elements = [...tmp];
      self.reinitializeDragSelectedItems(tmpSelection);
    }, 10);
  }

  public elementsCenterAlign(groupedAlign: boolean = false): void {
    
    if(this.dragSelected.length == 0) {
      for(let element of this.elements) {
        element.position.x = ( (this.content.nativeElement.clientWidth - element.width) / 2) - this.page.margin;
        element.left = 0;
      }
    } else {

      let farthestLeft: number = null;
      let farthestRight: number = null;

      for(let element of this.dragSelected) {

        if(farthestLeft == null || element.position.x < farthestLeft) {
          farthestLeft = element.position.x;
        }

        if(farthestRight == null || (element.position.x + element.width) > farthestRight) {
          farthestRight = element.position.x + element.width;
        }

      }

      let groupWidth: number = farthestRight - farthestLeft;
      let moveLeftCalc: number = 0;

      if(!groupedAlign) {
        moveLeftCalc = ( farthestLeft - ( ( (this.content.nativeElement.clientWidth - groupWidth) / 2) - this.page.margin ) );
      }

      for(let element of this.dragSelected) {
        if(groupedAlign) {
          let elementFarthestLeft: number = element.position.x;
          // let diff: number = (element.width / 2 ) + (element.width - groupWidth) / 2;
          element.position.x = farthestLeft + (groupWidth - element.width) / 2;
        } else {
          element.position.x -= moveLeftCalc;
        }

        element.left = 0;
      }

    }


    // Hack to make all elemnts update with new postiion
    let tmpSelection = [...this.dragSelected];
    let tmp = [...this.elements];
    let self = this;

    this.elements = [];

    setTimeout(function() { 
      self.elements = [...tmp];
      self.reinitializeDragSelectedItems(tmpSelection);
    }, 10);

  }

  public elementsRightAlign(groupedAlign: boolean = false): void {

    if(this.dragSelected.length == 0) {
      for(let element of this.elements) {
        element.position.x = this.content.nativeElement.clientWidth - element.width - this.page.margin;
        element.left = 0;
      }
    } else {

      let farthestRight: number = null;

      for(let element of this.dragSelected) {

        if(farthestRight == null || (element.position.x + element.width) > farthestRight) {
          farthestRight = element.position.x + element.width;
        }

      }

      let moveRightCalc: number = 0;

      if(!groupedAlign) {
        moveRightCalc = ( this.content.nativeElement.clientWidth - farthestRight - this.page.margin );
      }
      

      for(let element of this.dragSelected) {
        if(groupedAlign) {
          let elementFarthestRight: number = element.position.x + element.width;
          let diff: number = farthestRight - elementFarthestRight;
          element.position.x += diff;
        } else {
          element.position.x += moveRightCalc;
        }

        element.left = 0;
      }
    }


    // Hack to make all elemnts update with new postiion
    let tmpSelection = [...this.dragSelected];
    let tmp = [...this.elements];
    let self = this;

    this.elements = [];

    setTimeout(function() { 
      self.elements = [...tmp];
      self.reinitializeDragSelectedItems(tmpSelection);
    }, 10);
  }

  public reinitializeDragSelectedItems(items: Array<FormElement>): void {

    let self = this;

    setTimeout(function() { 
      self.dragSelected = [...items];
      
      self.selectContainer.selectItems( (element: FormElement) => { 
        let found: boolean = false;
        
        let selectedElement: FormElement = self.dragSelected.find( (selected: FormElement) => { return element.id == selected.id }); 

        if(selectedElement != undefined && selectedElement != null) {
          found = true;
        }

        return found;
      });

    }, 100);

  }

  public editorChanged(event: any, element: FormElement) {
    console.log("Editor Changed: ", event);

    if(event.event == 'selection-change') {

    } else {

      element.value = event.html;

    }

  }

  public getImg(imgIndex: number): void {
    if(this.imgs[imgIndex] != undefined) {
      this.imageHandler.getFile(this.imgs[imgIndex].id);
    }
  }

  public getAllImgs(): void {
    let imgIds: Array<string> = [];

    for(let img of this.imgs) {

      if(img != null && img.id.length > 0) {
        imgIds.push(img.id);
      }
      
    }

    this.imageHandler.getAsyncFiles(imgIds, this.companyId).then( (imgs: Array<any>) => {

      if(imgs) {
        let index: number = 0;

        for(let img of this.imgs) {

          if(img != null && img.id.length > 0) {
            img.file = imgs[index];
          }

          index++;

        }

      }

      console.log("Images: ", this.imgs);
      this.assignImgs();

    });
  }

  public assignImgs(): void {

    for(let img of this.imgs) {

      let elementIndex: number = this.elements.findIndex( (el: FormElement) => { 

        if(el.type == this.elementTypes.IMG) {

          if(el.img != null && el.img.id.length > 0) {
            return el.img.id == img.id 
          } else {
            return false;
          }

        } else {
          return false;
        }

      });

      if(elementIndex > -1) {
        this.elements[elementIndex].img.file = img.file;
      }

    }

  }

  public uploadImage(images: Array<any>, element: FormElement): void {

    console.log("Upload Images: ", images);

      if(images != null && images != undefined && images.length > 0) {

        for(let image of images) {
          let file: File = image.file;
          let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

          element.img.id = imgIdUnique;
  
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

            console.log("Scanning Elements");
            // PROBLEM - Very Inefficient And Must Change
            this.scanElements();
          });
  
        }

      }

  }

  public uploadTableImage(images: Array<any>, elementIndex: number): void {

    console.log("Upload Images: ", images);

      if(images != null && images != undefined && images.length > 0) {

        // for(let image of images) {
        //   let file: File = image.file;
        //   let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        //   this.elements[elementIndex].img.id = imgIdUnique;
  
        //   this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

        //     console.log("Scanning Elements");
        //     // PROBLEM - Very Inefficient And Must Change
        //     this.scanElements();
        //   });
  
        // }

      }

  }

  // public deleteImg(imgIndex: number): void {
  //   this.images.splice(imgIndex, 1);
  //   this.imgIds.splice(imgIndex, 1);
  //   this.imageDeleteAction = true;
  // }

  public moveUpLayer(element: FormElement): void {
      element.zIndex++;
  }

  public moveBackLayer(element: FormElement): void {

    if(element.zIndex > 0) {
      element.zIndex--;
    }

  }

  addTableCol(element: FormElement): void {

    element.table.cols.push( { id: this.navigatorService.generateKey(), title: "Column" } );

    for(let row of element.table.rows) {
      row.data.push("Data");
    }

  }

  removeTableCol(element: FormElement): void {

    if(element.table.rows.length > 0) {
      element.table.cols.splice(0, 1);

      for(let row of element.table.rows) {
        row.data.splice(0, 1);
      }
    }

  }

  addTableRow(element: FormElement): void {

    element.table.rows.push( { id: this.navigatorService.generateKey(), data: [ ] } );

    for(let column of element.table.cols) {
      element.table.rows[element.table.rows.length - 1].data.push("Data");
    }

  }

  removeTableRow(element: FormElement): void {

    if(element.table.rows.length > 0) {
      element.table.rows.splice(0, 1);
    }
    
  }


  public updateColTitle(title: string, element: FormElement, colIndex: number): void {
    element.table.cols[colIndex].title = title;
  }

  public turnOnEditColumnTitleMode(): void {
    if(this.builderMode) {
      this.editColumnTitleMode = true;
    }
  }

  public turnOffEditColumnTitleMode(): void {
    this.editColumnTitleMode = false;
  }

  public turnOnEditTitleMode(): void {
    if(this.builderMode || this.dealMode) {
      this.editTitleMode = true;
    }
  }

  public turnOffEditTitleMode(): void {
    this.editTitleMode = false;
  }

  public updateTitle(title: string): void {
    this.page.title = title;
  }

  public turnOnGridView(): void {
    this.gridView = true;
  }

  public turnOffGridView(): void {
    this.gridView = false;
  }

  public toggleBuilderMode(): void {

    if(this.builderMode) {
      this.builderMode = false;
    } else {
      this.builderMode = true
    }

    this.gridView = false;
  }

  public turnOnBuilderMode(): void {
    this.builderMode = true;
    this.gridView = false;
  }

  public turnOffBuilderMode(): void {
    this.builderMode = false;
  }

  public toggleHeader(): void {

    if(this.page.header.active) {
      this.page.header.active = false;
      this.headerHeight = 0;
    } else {
      this.page.header.active = true
      this.headerHeight = this.page.header.height;
    }

  }

  public toggleFooter(): void {

    if(this.page.footer.active) {
      this.page.footer.active = false;
      this.footerHeight = 0;
    } else {
      this.page.footer.active = true
      this.footerHeight = this.page.footer.height;
    }

  }

  public turnOnHeader(): void {
    this.displayHeader = true;
  }

  public turnOffHeader(): void {
    this.displayHeader = false;
  }

  public turnOnFooter(): void {
    this.page.footer.active = true;
  }

  public turnOffFooter(): void {
    this.page.header.active = false;
  }

  public inputTitleChange(title:string, element: FormElement): void {

    element.title = title;
    console.log("Elements: ", this.elements);
   
  }

  public inputPlaceholderChange(placeholder: string, element: FormElement): void {
    element.placeholder = placeholder;
  }

  public backgroundTransparentChanged(element: FormElement, checked: boolean): void {
    element.backgroundTransparent = checked;
  }

  public clearMode(): void {
    this.turnOffEditTitleMode();
    this.exitElementSettings();
    this.exitElementDatalink();
    this.exitGroupMode();
    this.turnOffEditColumnTitleMode();

    this.focusElementIndex = null;
    this.elementFocus = null;
  }

  public addTableRowCellPadding(element: FormElement): void {
    element.table.rowCellPadding++;
  }

  public reduceTableRowCellPadding(element: FormElement): void {

    if(element.table.rowCellPadding > 0) { 
      element.table.rowCellPadding--;
    }

  }

  public addElementHeight(element: FormElement): void {
    element.height++;
  }

  public reduceElementHeight(element: FormElement): void {

    if(element.height > 1) { 
      element.height--;
    }

  }

  public addElementWidth(element: FormElement): void {
    element.width++;
  }

  public reduceElementWidth(element: FormElement): void {

    if(element.width > 1) { 
      element.width--;
    }

  }

  public addTableHeaderCellPadding(element: FormElement): void {
    element.table.headerCellPadding++;
  }

  public reduceTableHeaderCellPadding(element: FormElement): void {

    if(element.table.headerCellPadding > 0) { 
      element.table.headerCellPadding--;
    }

  }

  public tableCellBordersOn(element: FormElement): void {
    element.table.bordersMode = true;
  }

  public tableCellBordersOff(element: FormElement): void {
    element.table.bordersMode = false;
  }

  public changeTableType(type: number, element: FormElement): void {
    element.table.type = type;
  }

  private addElementConnection(id: string): void { 
    this.elementConnections.push(id);

    console.log("Connections: ", this.elementConnections);
  }

  private removeElementConnection(id: string): void { 
    let connectionIndex: number = this.elementConnections.findIndex( (connection: string) => { return id == connection });

    if(connectionIndex > -1) {
      this.elementConnections.splice(connectionIndex, 1);
    }

  }


  private addElement(type: number): void {

    let id: string = this.navigatorService.generateKey();
    this.addElementConnection(id);

    switch(type) {

      case this.elementTypes.TEXTAREA:
        this.elements.push( {
          id: id,
          type: this.elementTypes.TEXTAREA,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 250,
          height: 100,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#d5d5d5",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;
      
      case this.elementTypes.INPUT:
        this.elements.push( {
          id: id,
          type: this.elementTypes.INPUT,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 200,
          height: 75,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#d5d5d5",
          color: "#000000",
          value: "",

          title: "Something",
          placeholder: "placeholder",
    
          elements: []
        });
        break;

      case this.elementTypes.DROPDOWN_MENU:
        this.elements.push( {
          id: id,
          type: this.elementTypes.DROPDOWN_MENU,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 200,
          height: 75,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#d5d5d5",
          color: "#000000",

          dropdownOptions: [ 'Option 1', 'Option 2', 'Option 3', 'Option 3'],
          value: "",

          title: "Something",
          placeholder: "placeholder",
    
          elements: []
        });
        break;

      case this.elementTypes.FILE_INPUT:
        break;

      case this.elementTypes.CHECKBOX:
        this.elements.push( {
          id: id,
          type: this.elementTypes.CHECKBOX,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 150,
          height: 35,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#d5d5d5",
          color: "#000000",
          value: "",

          title: "checkbox",
          placeholder: "placeholder",
    
          elements: []
        });
        break;

      case this.elementTypes.SIGNATURE:
        this.elements.push( {
          id: id,
          type: this.elementTypes.SIGNATURE,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 330,
          height: 130,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",

          signature: null,
    
          elements: []
        });
        break;

      case this.elementTypes.SIGNED:
        this.elements.push( {
          id: id,
          type: this.elementTypes.SIGNED,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 220,
          height: 130,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",

          signature: null,
    
          elements: []
        });
        break;

      case this.elementTypes.PARAGRAPH:
        this.elements.push( {
          id: id,
          type: this.elementTypes.PARAGRAPH,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 190,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.IMG:
        this.elements.push( {
          id: id,
          type: this.elementTypes.IMG,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 190,
          height: 180,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",

          // img: { id: "325dda3213930d7a4158ce45" },
          img: {
            id: ""
          },
    
          elements: []
        });

        // REMOVE - VERY INEFFICIENT
        this.scanElements();
        break;

      case this.elementTypes.VIDEO:
        break;

      case this.elementTypes.TABLE:
        this.elements.push( {
          id: id,
          type: this.elementTypes.TABLE,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 350,
          height: 250,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#ffffff",
          color: "#000000",
          value: "",

          table: {
            id: this.navigatorService.generateKey(),
            title: "Table",
            type: this.tableTypes.FIELD,
            headerCellPadding: 0,
            rowCellPadding: 0,
            cols: [ 
              { id: this.navigatorService.generateKey(), title: "Column" },
              { id: this.navigatorService.generateKey(), title: "Column" },
              { id: this.navigatorService.generateKey(), title: "Column" },
              { id: this.navigatorService.generateKey(), title: "Column" } 
            ],
            rows: [ 
              { id: this.navigatorService.generateKey(), data: [ "data", "data", "data", "data" ] },
              { id: this.navigatorService.generateKey(), data: [ "data", "data", "data", "data" ] },
              { id: this.navigatorService.generateKey(), data: [ "data", "data", "data", "data" ] } 
            ],
            bordersMode: true
          },
    
          elements: []
        });
        break;

      case this.elementTypes.DATE:
        this.elements.push( {
          id: id,
          type: this.elementTypes.DATE,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 200,
          height: 75,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#ffffff",
          color: "#000000",
          value: "",

          title: "Date",
          placeholder: "placeholder",
    
          elements: []
        });
        break;

      case this.elementTypes.CONTAINER:
        this.elements.push( {
          id: id,
          type: this.elementTypes.CONTAINER,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: false,
          backgroundColor: "#dbdbdb",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.MAP:
        this.elements.push( {
          id: id,
          type: this.elementTypes.MAP,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.HR:
        this.elements.push( {
          id: id,
          type: this.elementTypes.HR,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 5,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: false,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.VR:
        this.elements.push( {
          id: id,
          type: this.elementTypes.VR,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 5,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: false,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_SERVICES_RENDERED:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_SERVICES_RENDERED,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_LABOR_RENDERED:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_LABOR_RENDERED,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_PARTS_RENDERED:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_PARTS_RENDERED,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_EQUIPMENT_RENDERED:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_EQUIPMENT_RENDERED,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_CALENDAR:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_CALENDAR,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break; 

      case this.elementTypes.COMPONENT_PART_ATTACHER:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_PART_ATTACHER,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_FOLLOW_ON_WO:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_FOLLOW_ON_WO,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.COMPONENT_ASSIGN_STAFF:
        this.elements.push( {
          id: id,
          type: this.elementTypes.COMPONENT_ASSIGN_STAFF,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 400,
          height: 150,
          zIndex: 0,
          borderRadius: 0,
          backgroundTransparent: true,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.ACTION_BTN_REPEATER:
        this.elements.push( {
          id: id,
          type: this.elementTypes.ACTION_BTN_REPEATER,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 35,
          height: 35,
          zIndex: 0,
          borderRadius: 100,
          backgroundTransparent: false,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;

      case this.elementTypes.ACTION_BTN_REQUEST_APPROVAL:
        this.elements.push( {
          id: id,
          type: this.elementTypes.ACTION_BTN_REQUEST_APPROVAL,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 35,
          height: 35,
          zIndex: 0,
          borderRadius: 100,
          backgroundTransparent: false,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;
        
      case this.elementTypes.ACTION_BTN_SIGNATURE_REQUIRED:
        this.elements.push( {
          id: id,
          type: this.elementTypes.ACTION_BTN_SIGNATURE_REQUIRED,
          groupContainer: [],
          top: this.headerHeight,
          left: 0,
          position: { x: 0, y: 0 },
          width: 35,
          height: 35,
          zIndex: 0,
          borderRadius: 100,
          backgroundTransparent: false,
          backgroundColor: "#f3f3f3",
          color: "#000000",
          value: "",
    
          elements: []
        });
        break;
        
    }

    if(type == this.elementTypes.SIGNATURE || type == this.elementTypes.SIGNED ) {
      let self = this;
      setTimeout(
        function() { 
          self.elementFocus = self.elements[self.elements.length - 1];
          self.initializeSignaturePad();
        },
      500);
    }

    this.elementFocus = this.elements[this.elements.length - 1];
    this.cachePage();
  }

  public deleteElement(id: string): void {
    let elementIndex: number = this.elements.findIndex( (el: FormElement) => { return el.id == id });

    if(elementIndex > -1) {
      this.removeElementConnection(id);
      this.elements.splice(elementIndex, 1);
    }

  }

  public addElementToGroup(element: FormElement) {

    if(this.elementGroup != null && this.elementGroup.id != element.id) {

      console.log("Before Grouped Element: ", this.elements);

      let rmvIndex: number = this.elements.findIndex( (el: FormElement) => { return el.id == element.id });

      if(rmvIndex > -1) {
        element.groupContainer = [...this.elementGroup.groupContainer];
        element.groupContainer.push(this.elementGroup.id);

        this.elementGroup.elements.push(element);
        this.elements.splice(rmvIndex, 1);
      }

      else if(this.elementGroup.id == "locked_header" && rmvIndex > -1) {
        this.page.header.elements[0].elements.push(element);
        this.elements.splice(rmvIndex, 1);
      }

      else if(this.elementGroup.id == "locked_footer" && rmvIndex > -1) {
        this.page.footer.elements[0].elements.push(element);
        this.elements.splice(rmvIndex, 1);
      } else {
        this.snackBar.open('Cannot group a grouped element!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

      console.log("Grouped Element: ", this.elements);

    }

  }

  public deleteElementFromGroup(parent: FormElement, element: FormElement) {
    let elementIndex: number = parent.elements.findIndex( (el: FormElement) => { return el.id == element.id });

    if(elementIndex > -1) {
      this.removeElementConnection(element.id);
      parent.elements.splice(elementIndex, 1);
    }
  }

  public enterGroupMode(element: FormElement): void {
    this.elementGroup = element;
  }

  public exitGroupMode(): void {
    this.elementGroup = null;
  }

  public exitElementSettings(): void {
    this.elementSettingsFocus = null;
  }

  public expandPageMenu(): void {
    this.pageMenuExpanded = true;
  }

  public collapsePageMenu(): void {
    this.pageMenuExpanded = false;
  }

  public expandSaveAsPanel(): void {
    this.saveAsPanelExpanded = true;
  }

  public collapseSaveAsPanel(): void {
    this.saveAsPanelExpanded = false;
  }

  public expandDetailModePanel(): void {
    this.detailModeExpanded = true;
  }

  public collapseDetailModePanel(): void {
    this.detailModeExpanded = false;
  }

  public tabEditMode(): void {
    this.tabMode = this.tabModes.EDIT;
  }

  public tabViewMode(): void {
    this.tabMode = this.tabModes.VIEW;
  } 

  public expandSelectionAlignPanel(): void {
    this.selectionAlignMenuExpanded = true;
  }

  public collapseSelectionAlignPanel(): void {
    this.selectionAlignMenuExpanded = false;
  }

  public expandSubActionMenu(): void {
    this.subActionMenuExpanded = true;
  }

  public collapseSubActionMenu(): void {
    this.subActionMenuExpanded = false;
  }


  public openElementSettings(id: string): void {
    this.elementSettingsFocus = id;
  }

  public exitElementDatalink(): void {
    this.elementDatalinkFocus = null;
  }

  public openElementDatalink(id: string): void {
    this.elementDatalinkFocus = id;
  }

  public copyElement(element: FormElement): void {
    this.copiedElement = element;
  }



  gridHandleDragStart(category: number, element: FormElement): void {
    this.handleDragStart= true;
    this.handleDragStop = false;

    this.elementFocus = element;

    this.handleCategory = category;
  }

  gridHandleDragStop(): void {

    if(!this.handleDragStop) {
      this.handleDragStart= false;
      this.handleDragStop = true;

      this.shiftElements();
      this.resizeCanvas();
    }

  }

  gridHandleDrag(mouseEvent: any): void {

    if(this.handleDragStart) {

      if(this.elementFocus == undefined) {
        return;
      }

      switch(this.handleCategory) {

        case this.elementResizeHandleCategories.TOP:
          this.gridResizeTop(mouseEvent);
          break;

        case this.elementResizeHandleCategories.BOTTOM:
          this.gridResizeBottom(mouseEvent);
          break;

        case this.elementResizeHandleCategories.LEFT:
          this.gridResizeLeft(mouseEvent);
          break;

        case this.elementResizeHandleCategories.RIGHT:
          this.gridResizeRight(mouseEvent);
          break;

        case this.elementResizeHandleCategories.TOP_LEFT:
          this.gridResizeTop(mouseEvent);
          this.gridResizeLeft(mouseEvent);
          break;

        case this.elementResizeHandleCategories.TOP_RIGHT:
          this.gridResizeTop(mouseEvent);
          this.gridResizeRight(mouseEvent);
          break;

        case this.elementResizeHandleCategories.BOTTOM_LEFT:
          this.gridResizeBottom(mouseEvent);
          this.gridResizeLeft(mouseEvent);
          break;

        case this.elementResizeHandleCategories.BOTTOM_RIGHT:
          this.gridResizeBottom(mouseEvent);
          this.gridResizeRight(mouseEvent);
          break;

      }

    }

  }

  gridResizeTop(mouseEvent: any): void {
    console.log("Top Resize");

    if(this.handleDragStart) {

      let elementAdjHeight: number = -mouseEvent.movementY + this.elementFocus.height;
      let elementAdjTop: number = mouseEvent.movementY + this.elementFocus.top;

      // if(elementAdjTop >= 20) {
        this.elementFocus.top = elementAdjTop;
      // }

      // if(elementAdjHeight >= 20) {
        this.elementFocus.height = elementAdjHeight;
      // }

    }
  }

  gridResizeBottom(mouseEvent: any): void {
    console.log("Bottom Resize");
    
    if(this.handleDragStart) {

      let elementAdjHeight: number = mouseEvent.movementY + this.elementFocus.height;

      if(elementAdjHeight >= 20) {
        this.elementFocus.height = elementAdjHeight;
      }

    }
  }

  gridResizeLeft(mouseEvent: any): void {
    console.log("Left Resize");
    
    if(this.handleDragStart) {

      let elementAdjWidth: number = -mouseEvent.movementX + this.elementFocus.width;
      let elementAdjLeft: number = mouseEvent.movementX + this.elementFocus.left;


        this.elementFocus.width = elementAdjWidth;
        this.elementFocus.left = elementAdjLeft;
      

    }
  }

  gridResizeRight(mouseEvent: any): void {
    console.log("Right Resize");
    
    if(this.handleDragStart) {

      let elementAdjWidth: number = mouseEvent.movementX + this.elementFocus.width;

      if(elementAdjWidth >= 20) {
        this.elementFocus.width = elementAdjWidth;
      }

    }
  }

  validateEmail(email: string) {

    if(email.length > 0) {
     
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const validate: boolean = expression.test(email); // true

      return validate;
    } else {
      return false;
    }

  }

  esignDocument(element: FormElement): void {

    element.type = this.elementTypes.SIGNED;
    element.width = 330;
    element.height = 130;
    element.position.y -= element.height / 2;
    element.borderRadius = 0;
    element.backgroundTransparent = true;

    this.shiftElements();

    if(element.type == this.elementTypes.SIGNATURE || element.type == this.elementTypes.SIGNED ) {
      let self = this;
      setTimeout(
        function() { 
          self.elementFocus = element;
          self.registerListeners();
        },
      500);
    }

  }

  sendSignatureRequests(): void {

    if(this.page.signatureAccessPriviledge == undefined || this.page.signatureAccessPriviledge == null) {
      this.page.signatureAccessPriviledge = [];
    }

    if(this.page.signatureAccessPriviledge.length == 0) {
      this.snackBar.open('There are no requests to send, please add an esignature action button and submit a request in its settings menu!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    } else {

      this.snackBar.open('Requests are being sent out, please wait for confirmation of completion!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

      let document: FormDocument = this.page;
      document.id = this.navigatorService.generateKey();
      document.created = new Date();

      this.navigatorService.updateSignDocument(document).then( async (status: boolean) => {

        if(status) {

          for(let signer of document.signatureAccessPriviledge) {

            await this.twilioHandler.sendDocumentSignRequestEmail(document.id, signer.code, signer.email);

          }

          this.snackBar.open('All Complete, requests have been sent out!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

        }

      });

    }

  }

  signatureRequest(name: string, email: string, element: FormElement): void {

    const emailValidation: boolean = this.validateEmail(email);

    if(emailValidation && name.length > 3) {

      if(this.page.signatureAccessPriviledge == undefined || this.page.signatureAccessPriviledge == null) {
        this.page.signatureAccessPriviledge = [];
      }
      
      let signatureRequest: FormSignatureRequest = {
        id: this.navigatorService.generateKey(),
        name: name,
        email: email,
        signature: null,

        esignConsent: null,
        signedDate: null,

        requestDate: new Date(),
        requestedBy: this.navigatorService.getProfileId(),
        code: this.navigatorService.generateKey(this.keyTypes.SIGNATURE_SECURITY_CODE)
      };

      element.signatureRequest = signatureRequest;
      this.page.signatureAccessPriviledge.unshift(signatureRequest);

      this.navigatorService.getGeoLocation().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.navigatorService.getProfileId(),
          date: new Date(),
          type: this.documentAuditCategories.EMPLOYEE_EDITED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateDocument(this.page);
      });

    } else {

      if(name.length < 3) {
        this.snackBar.open('Request Name must contain a minimum of 3 characters!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

      if(!emailValidation) {
        this.snackBar.open('Not a valid email!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    }

  }

  saveSignaturePadData(): void {

    if(this.elementFocus.signaturePad != undefined) {
      let data = this.elementFocus.signaturePad.toData();

      
      this.resizeCanvas();

      this.elementFocus.signature = data;
      this.elementFocus.signaturePad.fromData(data);
      console.log("Signature Data: ", data);
    }

  }

  drawSignaturePadData(data: Array<any>): void {


    if(this.elementFocus.signaturePad != undefined && data != null && data != undefined) {
      this.resizeCanvas();

      this.elementFocus.signaturePad.fromData(data);
    }
    
  }

  resizeCanvas(element?: FormElement) {

    if(element == undefined || element == null) {
      element = this.elementFocus;
    }

    if(element.signatureCanvas != undefined) {
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      element.signatureCanvas.nativeElement.width = element.signatureCanvas.nativeElement.offsetWidth * ratio;
      element.signatureCanvas.nativeElement.height = element.signatureCanvas.nativeElement.offsetHeight * ratio;
      element.signatureCanvas.nativeElement.getContext("2d").scale(ratio, ratio);
      this.clearSignaturePad(); // otherwise isEmpty() might return incorrect value
    }
    
  }

  initializeSignaturePad(element?: FormElement, signatureBlockIndex?: number): void {
    let reinit: boolean = false;

    if(element == undefined || element == null) {
      element = this.elementFocus;
    } else {
      reinit = true;
    }

    this.resizeCanvas(element);

    if(reinit) {
      let signatureBlocks: Array<any> = this.signatureBlocks.toArray()
      element.signatureCanvas = signatureBlocks[signatureBlockIndex];
    } else {
      element.signatureCanvas = this.signatureBlocks.last;
    }

    element.signaturePad = new SignaturePad(element.signatureCanvas.nativeElement);
    element.signaturePad.penColor = "rgb(66, 133, 244)";
    element.signaturePad.throttle = 0;

    element.signaturePad.addEventListener("beginStroke", () => {
      console.log("Signature Starting");
      // this.saveSignaturePadData();
    });

    element.signaturePad.addEventListener("endStroke", () => {
      console.log("Signature Ended");
      this.saveSignaturePadData();
    });
    
  }

  clearSignaturePad(): void {

    if(this.elementFocus.signaturePad != undefined) {
      this.elementFocus.signaturePad.clear();
    }

  }

  getPNGBase64Signature(): string {

    if(this.elementFocus.signaturePad != undefined) {
      return this.elementFocus.signaturePad.toDataURL();
    }
    
  }


  public elementValueChange(element: FormElement, value: any): void {
    element.value = value;
  }

  // =======================
  //       Ext. Access
  // =======================

  public allowExtAccessChange(toggle: boolean): void {

    console.log("Allow Ext. Access: ", toggle);

    if(this.workOrder != undefined && this.workOrder != null) {
      this.workOrder.extAccess = toggle;

      this.updateWorkOrder().then( (status: boolean) => {

        if(status) {

          if(this.page.extAccess) {
            this.snackBar.open('The gates are open and you\'re free to share!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          } else {
            this.snackBar.open('The gates are shut and nobody can get in!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          }

        } else {
          this.snackBar.open('Sorry, but there was an issue processing your request. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

      });

    }

  }

  public openAccessPanel(): void {
    this.accessPanel = true;
  }

  public closeAccessPanel(): void {
    this.accessPanel = false;
    this.editAccessPriviledge = null;
    this.accessControlForm.reset();
  }

  public editAccess(priviledge: FormDocumentExtAccess): void {
    this.accessControlForm.controls["email"].setValue(priviledge.email);
    this.accessControlForm.controls["phone"].setValue(priviledge.phone);
    this.accessControlForm.controls["expireDate"].setValue(priviledge.expireDate);

    this.editAccessPriviledge = priviledge;
    this.openAccessPanel();
    this.extAccessTableRef.nativeElement.scrollTop = 0;
  }

  public deleteAccess(priviledge: FormDocumentExtAccess): void {

    if(this.workOrder != undefined && this.workOrder != null) {
      let priviledgeIndex: number = this.workOrder.extAccessPriviledge.findIndex( (access: FormDocumentExtAccess) => { return access.id == priviledge.id });

      if(priviledgeIndex > -1) {
        this.workOrder.extAccessPriviledge.splice(priviledgeIndex, 1);
      }

      this.updateWorkOrder().then( (status: boolean) => {

          if(status) {
            this.snackBar.open('Access Removed!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          } else {
            this.snackBar.open('There was an issue with your request, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          }

      });

    }

  }

  public saveNewPermission(): void {

    if(this.workOrder != undefined && this.workOrder != null) {

      if(this.accessControlForm.valid) {

        if(this.workOrder.extAccessPriviledge == undefined || this.workOrder.extAccessPriviledge == null) {
          this.workOrder.extAccessPriviledge = [];
        }

        let email: string = this.accessControlForm.controls["email"].value;
        let phone: string = this.accessControlForm.controls["phone"].value;
        let expireDate: Date = this.accessControlForm.controls["expireDate"].value;
        let code: string = this.navigatorService.generateKey(this.keyTypes.WORK_ORDER_ACCESS_CODE);

        if(this.editAccessPriviledge == undefined || this.editAccessPriviledge == null) {
          this.workOrder.extAccessPriviledge.push({
            id: this.navigatorService.generateKey(),
            email: email,
            phone: phone,
            code: code,
          
            createdBy: this.navigatorService.getProfileId(),
            createdDate: new Date(),
            expireDate: expireDate
          });
        } else {
          let accessControl: FormDocumentExtAccess = this.workOrder.extAccessPriviledge.find( (access: FormDocumentExtAccess) => { return access.id == this.editAccessPriviledge.id});

          if(accessControl != undefined && accessControl != null) {
            accessControl.email = email;
            accessControl.phone = phone;
            accessControl.expireDate = expireDate;
          }
        }

        console.log("Access Granted: ", this.workOrder.extAccessPriviledge);
        this.updateWorkOrder().then( (status: boolean) => {

          if(status) {
            this.snackBar.open('Access Granted!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

            if(this.editAccessPriviledge == null) {
              this.twilioHandler.sendWorkOrderAccessEmail(this.workOrder.workOrderId, code, email);
            }

          } else {
            this.snackBar.open('There was an issue with your request, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          }

        });

        this.closeAccessPanel();
      } else {
        this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    }

  }


  // =======================
  //        Data Link
  // =======================

  public changeDatalinkType(element: FormElement, type: number): void {

    if(element.datalinkType != type) {
      element.datalinkType = type;
      element.datalink = null;

      switch(type) {

        case this.datalinkTypes.DEAL:
          this.dataLookupKeys = [];
          break;

        case this.datalinkTypes.WORK_ORDER:
          this.dataLookupKeys = this.linkedDataKeyLookup();
          break;

        case this.datalinkTypes.INVOICE:
          this.dataLookupKeys = [];
          break;

      }

    } else {
      element.datalinkType = null;
      element.datalink = null;

      this.dataLookupKeys = [];
    }

  }

  async getForeignWorkOrders(): Promise<void> {
    let foreignWorkOrders: Array<any> = [];

    if(this.workOrder.foreign_work_orders != undefined && this.workOrder.foreign_work_orders != null) {

      for(let foreignKey of this.workOrder.foreign_work_orders) {

        await this.navigatorService.getWorkOrder(foreignKey).then( (workOrder: WorkOrder) => {

          if(workOrder != null) {
            foreignWorkOrders.push(workOrder);
          }

        });

      }

      this.foreign_work_orders = foreignWorkOrders;

    }

  }

  private getCustomWorkOrderFields(): void {

    this.navigatorService.getAsyncCustomWorkOrderCustomFields().then(customFields => {
      if(customFields) {
        this.customWorkOrderFields = customFields;
      }
    });

  }

  private getCustomWorkOrderFieldsAsForeign(companyId: string): void {

    this.navigatorService.getAsyncCustomWorkOrderCustomFieldsAsForeign(companyId).then(customFields => {
      if(customFields) {
        this.customWorkOrderFields = customFields;
      }
    });

  }

  datalinkSelectionCompare(o1: any, o2: any) {
    return (o1.data == o2.data);
  }

  linkedDataKeyLookup(): Array<LinkedData> {
    let keyLookup: Array<LinkedData> = [];

    // TEXTAREA = 0,
    // INPUT = 1,
    // FILE_INPUT = 2,
    // CHECKBOX = 3,
    // SIGNATURE = 4,
    // PARAGRAPH = 5,
    // IMG = 6,
    // VIDEO = 7,
    // TABLE = 8,
    // IMG_TABLE = 9,
    // DATE = 10,
    // CONTAINER = 11,
    // MAP = 12,
    // HR = 13,
    // VR = 14,
  
  
    // COMPONENT_SERVICES_RENDERED = 30,
    // COMPONENT_LABOR_RENDERED = 31,
    // COMPONENT_PARTS_RENDERED = 32,
    // COMPONENT_EQUIPMENT_RENDERED = 33,
    // COMPONENT_CALENDAR = 34,

    switch(this.elementFocus.type) {
      case this.elementTypes.INPUT:

        // W/O Info 

        // THESE MUST BE LOCKED DOWN
        keyLookup.push({ title: "Technician ID", data: "technicianId" });
        keyLookup.push({ title: "Originator ID", data: "originatorId" });
        keyLookup.push({ title: "W/O ID", data: "workOrderId" });
        keyLookup.push({ title: "Minutes Required", data: "timeStretch" });
        keyLookup.push({ title: "Initial Contact Date", data: "dateInitialContact" });
        keyLookup.push({ title: "Date Scheduled", data: "dateScheduled" });
        keyLookup.push({ title: "Origin Date", data: "originationDate" });
        
        // POC Contact
        keyLookup.push({ title: "POC Full Name", data: "custPocName" });
        keyLookup.push({ title: "POC Phone Number", data: "custPocPhone" });
        keyLookup.push({ title: "POC Email", data: "custPocEmail" });

        // Customer Contact
        keyLookup.push({ title: "First Name", data: "custFirstName" });
        keyLookup.push({ title: "Middle Name", data: "custMiddleName" });
        keyLookup.push({ title: "Last Name", data: "custLastName" });
        keyLookup.push({ title: "Email", data: "custEmail" });
        keyLookup.push({ title: "Phone Number", data: "custPhone" });

        // Address
        keyLookup.push({ title: "Street", data: "custAddress.street" });
        keyLookup.push({ title: "City", data: "custAddress.city" });
        keyLookup.push({ title: "State", data: "custAddress.state" });
        keyLookup.push({ title: "Zip Code", data: "custAddress.zip" });

        // Billing Address
        keyLookup.push({ title: "Billing Street", data: "custBillingAddress.street" });
        keyLookup.push({ title: "Billing City", data: "custBillingAddress.city" });
        keyLookup.push({ title: "Billing State", data: "custBillingAddress.state" });
        keyLookup.push({ title: "Billing Zip Code", data: "custBillingAddress.zip" });

        // Billing Amount
        keyLookup.push({ title: "Bill Total", data: "custBillAmount" });

        for(let customField of this.customWorkOrderFields) {
          keyLookup.push({
            title: customField.title,
            data: customField.key
          })
        }

        break;

      case this.elementTypes.TEXTAREA:
        keyLookup.push({ title: "Description", data: "description" });
        keyLookup.push({ title: "Notes", data: "specialNotes" });
        break;

      case this.elementTypes.PARAGRAPH:
        keyLookup.push({ title: "Description", data: "description" });
        keyLookup.push({ title: "Notes", data: "specialNotes" });
        break;

      case this.elementTypes.IMG_IMPORT:
        break;

      case this.elementTypes.DROP_DOWN_MENU:
        keyLookup.push({ title: "Priority", data: "priority" });
        keyLookup.push({ title: "Skill Level", data: "skillLvl" });
        keyLookup.push({ title: "Status", data: "status" });
        keyLookup.push({ title: "Completed", data: "isCompleted" });
        keyLookup.push({ title: "Paid", data: "isPaid" });
        keyLookup.push({ title: "Employee Group", data: "technicianId" });
        break;

      case this.elementTypes.FOLLOW_ON_TABLE:
        keyLookup.push({ title: "Follow On W/O's", data: "foreign_work_orders" });
        break;

      case this.elementTypes.COMPONENT_SERVICES_RENDERED:
        keyLookup.push({ title: "Services Rendered", data: "servicesRendered" });
        break;

      case this.elementTypes.COMPONENT_PART_ATTACHER:
        keyLookup.push({ title: "Attached Inventory", data: "attachedParts" });
        break;

      case this.elementTypes.COMPONENT_FOLLOW_ON_WO:
        keyLookup.push({ title: "Follow-on WO", data: "foreign_work_orders" });
        break;

      case this.elementTypes.COMPONENT_ASSIGN_STAFF:
        keyLookup.push({ title: "Assign Staff", data: "locked_assignedStaff" });
        break;
    }

    console.log("Work Order Keys: ", keyLookup);
    return keyLookup;
  }

  addNewDataLink(): void {
    // this.addNewLinkedField = true;
  }

  saveNewDataLink(title: string): void {
    // let fieldId: string = "custom-" + this.vendorService.generateKey(this.keyTypes.FIELD_ID);
    // let customField: WorkOrderCustomField = {
    //   key: fieldId,
    //   title: title,
    //   data: null
    // };

    // this.vendorService.updateWorkOrderCustomField(customField);
    // this.getWorkOrderCustomFields();
    // this.addNewLinkedField = false;
  }

  cancelNewDataLink(): void {
    // this.addNewLinkedField = false;
  }

  // The data is pulled from the work order and so the data type is unknown and thus we declared it as any.
  public getLinkedWorkOrderData(key: LinkedData): any {

    let data: any = null;

    // Checking for a Custom Field
    if(key.data.indexOf("custom") > -1) {
      data = this.workOrder.customFields[this.workOrder.customFields.findIndex(element => element.key == key.data)].data;
    } 

    else if(key.data.indexOf("locked_assignedStaff") > -1) {

      if(this.workOrder.assignedStaff == undefined || this.workOrder.assignedStaff == null) {
        this.workOrder.assignedStaff = [];
      }

      if(this.workOrder.assignedTeams == undefined || this.workOrder.assignedTeams == null) {
        this.workOrder.assignedTeams = [];
      }

      data = {
        staff: this.workOrder.assignedStaff,
        teams: this.workOrder.assignedTeams
      }

      if(this.workOrder.technicianId != undefined && this.workOrder.technicianId != null) {
        data.staff.push(this.workOrder.technicianId);
      }

    } 

    else if(key.data == "foreign_work_orders") {
      if(this.workOrder[key.data] == undefined) {
        this.workOrder[key.data] = [];
      }

      data = this.workOrder.workOrderId;

      this.getForeignWorkOrders();
    } 
    
    // Checking for multi level data
    else if(key.data.indexOf(".") > -1) {
      // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
      let key1 = key.data.substring(0, key.data.indexOf('.'));
      let key2 = key.data.substring(key.data.indexOf('.') + 1, key.data.length);


      if(this.workOrder[key1] != undefined && this.workOrder[key1][key2] != undefined) {
        data = this.workOrder[key1][key2];
      }
      
    }

    else {
      data = this.workOrder[key.data];
    }

    return data;
  }

  public getLinkedWorkOrderComponent(key: string): any {
    let data: any = null;

    if(key == "foreign_work_orders") {
      if(this.workOrder[key] == undefined) {
        this.workOrder[key] = [];
      }

      this.getForeignWorkOrders();
    } else {
      data = this.workOrder[key];
    }
    
    return data;
  }

  public linkedWorkOrderDataChange(key: LinkedData, change: any): void {
    // this.linkedData.title = option;
    // this.linkedData.data = this.findDataValueWithKey(this.workOrderLookupKeys, this.linkedData.title);

    // this.linkedDataTitle = option;
    // return option.title;
    console.log("Data Change: ", change);
    
    if(key.data.indexOf("locked_nokey") == -1) {
      
      // Checking for a Custom Field
      if(key.data.indexOf("custom") > -1) {
        this.workOrder.customFields[this.workOrder.customFields.findIndex(element => element.key == key.data)].data = change;
      } 
      
      // Checking for multi level data
      else if(key.data.indexOf(".") > -1) {
        // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
        let key1 = key.data.substring(0, key.data.indexOf('.'));
        let key2 = key.data.substring(key.data.indexOf('.') + 1, key.data.length);


        if(this.workOrder[key1] != undefined && this.workOrder[key1][key2] != undefined) {
          this.workOrder[key1][key2] = change;
        }
        
      }

      else {
        this.workOrder[key.data] = change;
      }

    }

    // console.log("Work Order Change: ", this.workOrderData);
  }

  public linkedWorkOrderComponentChange(key: string, change: any): void {

    if(key == "foreign_work_orders") {
      if(this.workOrder[key] == undefined) {
        this.workOrder[key] = [];
      }

      this.workOrder[key].push(change);
      this.getForeignWorkOrders();
    } else {
      this.workOrder[key] = change;
    }
    

  }

  public updateWorkOrderByLinks(): void {
    for(let page of this.page.pages) {

      for(let element of page.elements) {

        if(element.datalinkType != undefined && element.datalinkType != null && element.datalinkType == this.datalinkTypes.WORK_ORDER) {

          if(element.datalink != undefined && element.datalinkType != null) {

            let change: any = null;

            switch(element.type) {

              case this.elementTypes.TEXTAREA:
                change = element.value;
                break;
              
              case this.elementTypes.INPUT:
                change = element.value;
                break;
        
              case this.elementTypes.FILE_INPUT:
                break;
        
              case this.elementTypes.CHECKBOX:
                change = element.value;
                break;
        
              case this.elementTypes.SIGNATURE:
                change = element.signature;
                break;
        
              case this.elementTypes.PARAGRAPH:
                change = element.value;
                break;
        
              case this.elementTypes.IMG:
                change = element.img;
                break;
        
              case this.elementTypes.VIDEO:
                break;
        
              case this.elementTypes.TABLE:
                break;
        
              case this.elementTypes.DATE:
                change = element.value;
                break;
        
              case this.elementTypes.CONTAINER:
                break;
        
              case this.elementTypes.MAP:
                break;
        
              case this.elementTypes.HR:
                break;
        
              case this.elementTypes.VR:
                break;
        
              case this.elementTypes.COMPONENT_SERVICES_RENDERED:
                change = element.services;
                break;
        
              case this.elementTypes.COMPONENT_LABOR_RENDERED:
                change = element.services;
                break;
        
              case this.elementTypes.COMPONENT_PARTS_RENDERED:
                change = element.services;
                break;
        
              case this.elementTypes.COMPONENT_EQUIPMENT_RENDERED:
                change = element.services;
                break;
        
              case this.elementTypes.COMPONENT_CALENDAR:
                break;
        
              case this.elementTypes.ACTION_BTN_REPEATER:
                break;
        
              case this.elementTypes.ACTION_BTN_REQUEST_APPROVAL:
                break;
                
              case this.elementTypes.ACTION_BTN_SIGNATURE_REQUIRED:
                break;
                
            }


            if(change != null) {
              this.linkedWorkOrderDataChange(element.datalink, change);
            }

          }

        }

      }

    }
  }

  public updateWorkOrderByElements(elements: Array<FormElement>): void {

    for(let element of elements) {

      if(element.datalinkType != undefined && element.datalinkType != null) {

        if(element.datalink != undefined && element.datalink != null) {

          switch(element.type) {

            case this.elementTypes.IMG:
              this.linkedDataChange(element.datalink, element.datalinkType, element.img);
              break;

            case this.elementTypes.COMPONENT_SERVICES_RENDERED:
              this.linkedDataChange(element.datalink, element.datalinkType, element.services);
              break;
        
            case this.elementTypes.COMPONENT_LABOR_RENDERED:
              this.linkedDataChange(element.datalink, element.datalinkType, element.services);
              break;
        
            case this.elementTypes.COMPONENT_PARTS_RENDERED:
              this.linkedDataChange(element.datalink, element.datalinkType, element.services);
              break;
      
            case this.elementTypes.COMPONENT_SERVICES_RENDERED:
              this.linkedDataChange(element.datalink, element.datalinkType, element.services);
              break;

            default:
              this.linkedDataChange(element.datalink, element.datalinkType, element.value);
              break;

          }

        }

      }

    }

  }

  public saveWorkOrder(): void {

    for(let page of this.page.pages) {
      this.updateWorkOrderByElements(page.elements);
    }

    if(this.foreignViewer) {
      this.navigatorService.updateWorkOrderAsForeign(this.workOrder).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Save Complete!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
            this.snackBar.open('Sorry, but there was an issue processing your request. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

      });

    } else {
      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Save Complete!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
            this.snackBar.open('Sorry, but there was an issue processing your request. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

      });
    }

  }

  private async updateWorkOrder(): Promise<boolean> {
    let status: boolean = false;

    if(this.workOrder != undefined && this.workOrder != null) {

      if(this.foreignViewer) {

        await this.navigatorService.updateWorkOrderAsForeign(this.workOrder).then( (completed: boolean) => {

          status = completed;

        });

      } else {

        await this.navigatorService.updateWorkOrder(this.workOrder).then( (completed: boolean) => {

          status = completed;

        });

      }

    }

    return status;
  }

  public linkedDataChange(link: LinkedData, type: number, value: any): void {

    switch(type) {

      case this.datalinkTypes.DEAL:
        break;

      case this.datalinkTypes.WORK_ORDER:
        this.linkedWorkOrderDataChange(link, value);
        break;

      case this.datalinkTypes.INVOICE:
        break;

    }

  }

  public assignedStaffUpdate(staff: Array<string>): void {

    if(this.workOrder != undefined && this.workOrder != null) {
      this.workOrder.assignedStaff = staff;
    }

  }

  public assignedTeamsUpdate(teams: Array<string>): void {

    if(this.workOrder != undefined && this.workOrder != null) {
      this.workOrder.assignedTeams = teams;
    }

  }

  public attachFormToDeal(): void {

    if(this.deal != undefined && this.deal != null) {

      this.navigatorService.getAsyncCRMDeal(this.deal.id).then( (deal: CRMDeal) => {

        let documentedForm: DocumentedForm = {
          id: this.page.id,
          form: this.page.id,
          desc: this.page.title,
          created_date: new Date(),
          created_by: this.navigatorService.getProfileId()
        };

        if(deal.documentedFormTrail == undefined || deal.documentedFormTrail == null) {
          deal.documentedFormTrail = [ ];
        }

        deal.documentedFormTrail.unshift(documentedForm);

        this.navigatorService.upsertCRMDeal(deal);

      });

    }

  }

  public saveDocument(isAttachment: boolean = false): void {

    this.snackBar.open('Document Saved', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

    let documentedForm: DocumentedForm = {
      id: this.page.id,
      form: this.page.id,
      desc: this.page.title,
      created_date: new Date(),
      created_by: this.navigatorService.getProfileId()
    };

    if(this.page.audit == undefined || this.page.audit == null) {
      this.page.audit = [];
    }

    if(this.deal != undefined && this.deal != null && isAttachment) {
      
      if(!this.foreignViewer) {
        this.attachFormToDeal();
      }

    }

    if(this.workOrderId != undefined && this.workOrderId != null) {
      
      if(!this.foreignViewer) {
        this.navigatorService.getWorkOrder(this.workOrderId).then( (workOrder: WorkOrder) => {

          if(workOrder != undefined && workOrder != null) {

            this.workOrder == workOrder;

            this.updateWorkOrderByLinks();

            this.navigatorService.updateWorkOrder(workOrder);
          }

        });
      } else {
        this.navigatorService.getWorkOrderAsForeign(this.companyId, this.workOrderId).then( (workOrder: WorkOrder) => {

          if(workOrder != undefined && workOrder != null) {
            this.workOrder == workOrder;

            this.updateWorkOrderByLinks();

            this.navigatorService.updateWorkOrderAsForeign(workOrder);
          }

        });
      }

    }

    if(this.invoiceId != undefined && this.invoiceId != null) {
      
    }

    if(isAttachment) {
      this.attachmentSavedEmitter.emit(documentedForm);
    } else {
      this.documentSavedEmitter.emit(documentedForm);
    }

    

    if(!this.foreignViewer) {

      this.navigatorService.getGeoLocation().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.navigatorService.getProfileId(),
          date: new Date(),
          type: this.documentAuditCategories.EMPLOYEE_EDITED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateDocument(this.page);
      });

      
    } else {

      this.navigatorService.getGeoLocationAsForeign().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.extId,
          date: new Date(),
          type: this.documentAuditCategories.SUBCONTRACTOR_EDITED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateDocumentAsForeign(this.page);
      });

    }

  }

  public saveWorkOrderDocument(): void {

    this.snackBar.open('Document Saved', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

    if(this.page.audit == undefined || this.page.audit == null) {
      this.page.audit = [];
    }

    if(!this.foreignViewer) {

      this.navigatorService.getGeoLocation().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.navigatorService.getProfileId(),
          date: new Date(),
          type: this.documentAuditCategories.EMPLOYEE_EDITED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateWorkOrderVersion(this.page);
      });
      
    }

  }

  public saveTemplate(isTemplateEdit: boolean = false): void {
    this.snackBar.open('Template Saved', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

    if(!isTemplateEdit) {
      this.page.id = this.navigatorService.generateKey(this.keyTypes.FORM_ID);
    }

    if(this.page.audit == undefined || this.page.audit == null) {
      this.page.audit = [];
    }

    if(this.dealId != undefined && this.dealId != null) {

    }

    if(!this.foreignViewer) {

      this.navigatorService.getGeoLocation().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.navigatorService.getProfileId(),
          date: new Date(),
          type: this.documentAuditCategories.EMPLOYEE_EDITED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateTemplate(this.page);
      });

      
    } else {

      this.navigatorService.getGeoLocationAsForeign().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.extId,
          date: new Date(),
          type: this.documentAuditCategories.SUBCONTRACTOR_EDITED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateTemplateAsForeign(this.page);
      });

    }

  }

  public saveSignDocument(): void {

    if(!this.foreignViewer) {

      this.navigatorService.getGeoLocation().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.navigatorService.getProfileId(),
          date: new Date(),
          type: this.documentAuditCategories.EMPLOYEE_SIGNED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateDocument(this.page);
      });

      
    } else {

      this.navigatorService.getGeoLocationAsForeign().then( (geo: LatLng) => {

        this.page.audit.unshift({
          id: this.navigatorService.generateKey(),
          tender: this.extId,
          date: new Date(),
          type: this.documentAuditCategories.SIGNER_SIGNED,
        
          ipData: this.navigatorService.getIP(),
          geo: geo,
        });
  
        this.navigatorService.updateDocumentAsForeign(this.page);
      });

    }

  }

  cdkDragStarted(): void {
    console.log("Drag Started");
  }

  cdkDragInitialElementStarted(): void {
    console.log("Drag Initial Element Started");
    this.incomingElement = true;
  }

  cdkDragMoved(event: any, element: FormElement): void {
    // element.position.x += event.event.movementX;
    // element.position.y += event.event.movementY;

    for(let selectedElement of this.dragSelected) {

      if(element.id != selectedElement.id) {
        // selectedElement.left += event.event.movementX;
        // selectedElement.top += event.event.movementY;

        // selectedElement.left += event.event.movementX;
        // selectedElement.top += event.event.movementY;

        // Fuck no
        selectedElement.left = event.distance.x;
        selectedElement.top = event.distance.y;
      }
      
    }

  }

  cdkDragStopped(event: any, element: FormElement): void {
    console.log("Drag Stopped");

    let bodyWidth: number = this.contentBody.nativeElement.clientWidth;

    for(let selectedElement of this.dragSelected) {

      if(element.id != selectedElement.id) {

        selectedElement.position.x += selectedElement.left;
        selectedElement.position.y += selectedElement.top;

        selectedElement.left = 0;
        selectedElement.top = 0;

      }
      
    }

    element.position.x += event.distance.x;
    element.position.y += event.distance.y;

    if( (element.position.x + element.left) < 0) {
      element.left = 0;
      element.position.x = 0;
    }

    if( (element.position.x + element.width + element.left) > bodyWidth) {
      element.position.x = bodyWidth - element.width - element.left;
    }

    this.shiftElements();
  }

  drop(event: CdkDragDrop<string[]>) {

    console.log("Drop: ", event);

    // Same Container
    // Using distance to not allow element to even temporarily disappear
    if (event.distance.x >= -50) {


    } 
    
    else {
      console.log("Drop: ", event);
      let type: number = event.item.data;
      let element: HTMLElement = event.item.element.nativeElement;

      this.addElement(type);

      if(this.page.header.active) {
        let diff: number = (element.offsetTop + event.distance.y) - element.offsetHeight;
        
        if(diff <= this.page.header.height) {
          this.elementGroup = this.page.header.elements[0];
          this.addElementToGroup(this.elementFocus);
          this.elementGroup = null;
        }

      }

      if(this.page.footer.active) {
        let diff: number = (element.offsetTop + event.distance.y) - element.offsetHeight;

        if(diff >= this.documentHeight - this.page.footer.height) {
          this.elementGroup = this.page.footer.elements[0];
          this.addElementToGroup(this.elementFocus);
          this.elementGroup = null;
        }

      }

    }

    this.incomingElement = false;

    // this.cdkDragStopped();
  }

}