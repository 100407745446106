import { Component } from '@angular/core';
import { VectorDBService } from '../../services/vendor/vector-db.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  selectedFiles: File[] = [];
  uploadStatus: string = '';

  constructor(private vectorDBService: VectorDBService) {}

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    this.selectedFiles = Array.from(files);
  }

  onUpload() {
    if (this.selectedFiles.length === 0) {
      this.uploadStatus = 'No files selected.';
      return;
    }

    this.uploadStatus = 'Uploading...';

    this.vectorDBService.uploadFiles(this.selectedFiles)
      .subscribe({
        next: (response) => {
          this.uploadStatus = 'Upload successful!';
          console.log('Upload Response:', response.data);
        },
        error: (error) => {
          this.uploadStatus = 'Upload failed. Please try again.';
          console.error('Upload Error:', error);
        }
      });
  }

  query() {

    this.vectorDBService.query()
      .subscribe({
        next: (response) => {
          console.log('Query Response:', response.data);
        },
        error: (error) => {
          this.uploadStatus = 'Query failed. Please try again.';
          console.error('Query Error:', error);
        }
      });
  }

}
