import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { NavigatorService } from './navigator.service';
import { PlatformService } from './platform.service';


@Injectable({
    providedIn: 'root'
})

export class AiChatService {


    constructor(
        private http: HttpClient, 
        private navigatorService: NavigatorService, 
        private platformService: PlatformService,
        public router: Router) { 

    }

    public init() {
        this.initViewState();
    }


    private initViewState(): void { }

   
    public async sendAiChatMsg(msg: string, consultation: any): Promise<string | null> {

        let aiResponse: any = "";
        const token: string | null = this.platformService.getAuthToken();

        await this.http.post(`${environment.hostURL}/platform/newton/api/sendConsultationMessage`, 
        { token: token, consultation: consultation, message: msg } ).toPromise().then( 
            (data: { message: string, agentResponse: string }
                ) => {
                    console.log("AI Response", data );
                    aiResponse = JSON.parse(data?.agentResponse);
                }
        );

        return aiResponse?.message;
    }

}