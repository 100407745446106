import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VectorDBService {
  private readonly uploadUrl = 'https://swarmoperative.ngrok.app/api/langchain/upload'; // Adjust to your Node server endpoint
  private readonly queryUrl = 'https://swarmoperative.ngrok.app/api/langchain/query'; // Adjust to your Node server endpoint

  constructor() {}

  // Upload files with optional metadata
  uploadFiles(files: File[], metadata?: any): Observable<AxiosResponse<any>> {
    const formData = new FormData();

    // Append files to FormData
    files.forEach((file, index) => {
      formData.append(`file`, file, file.name);
    });

    // Append metadata if provided
    if (metadata) {
      formData.append('metadata', JSON.stringify(metadata));
    }

    return new Observable(observer => {
      axios.post(this.uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
          console.log('Upload Progress:', progress + '%');
        }
      })
      .then(response => {
        observer.next(response);
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }

  query(): Observable<AxiosResponse<any>> {

    const query: string = "What's the most technical role that he's ever held?"
    
    return new Observable(observer => {
        axios.post(this.queryUrl, {query: query})
        .then(response => {
          observer.next(response);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
      });

  }
}
