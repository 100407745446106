import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WorkOrderCustomField } from '../../data-models/models';
import { NavigatorService } from './navigator.service';

import { PlatformService } from './platform.service';


@Injectable({
    providedIn: 'root'
})

export class WorkOrdersService {

    constructor(
        private http: HttpClient, 
        private platformService: PlatformService,
        private navigatorService: NavigatorService,
        public router: Router) { 

    }

    public init() {
        this.initViewState();
    }


    private initViewState(): void { }

    public async getCustomFields(): Promise<Array<WorkOrderCustomField>> {
        const customFields: Array<WorkOrderCustomField> = await this.navigatorService.getAsyncCustomWorkOrderCustomFields();
        return customFields;
    }

    public async upsertCustomField(field: WorkOrderCustomField): Promise<boolean> {
        return await this.navigatorService.updateWorkOrderCustomField(field);
    }

    public async deleteCustomField(field: WorkOrderCustomField): Promise<boolean> {
        return await this.navigatorService.deleteWorkOrderCustomField(field);
    }

    
    // Temp Disabled - Transition off of Deprecated Mongo, this is a more permenant patch
    // public async getCustomFields(): Promise<Array<WorkOrderCustomField>> {

    //     let customFields: Array<WorkOrderCustomField> = [];
    //     const token: string | null = this.platformService.getAuthToken();

    //     await this.http.post(`${environment.hostURL}/platform/newton/api/sendConsultationMessage`, 
    //     { token: token } ).toPromise().then( 
    //         (data: { message: string, fields: string }
    //             ) => {
    //                 console.log("Custom WO Fields", data );
    //                 customFields = JSON.parse(data?.fields);
    //             }
    //     );

    //     return customFields;
    // }

}