import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'zone.js/dist/zone';

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InputFileModule } from 'ngx-input-file';
import { NgChatModule } from './ng-chat/ng-chat.module';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { VendorAssetWorkOrderCalendarModule } from './professional/vendor-asset-work-order-calendar/vendor-asset-work-order-calendar-module';

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PrebuiltComponentsModule } from './content-builder/prebuilt-components/prebuilt-components.module';
import { ColorPickerModule } from 'ngx-color-picker';

import { ChartsModule } from 'ng2-charts';
// import { NgChatModule } from 'ng-chat';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true               
};
 
import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module'; 

import { QuillModule } from 'ngx-quill'

import { AgmCoreModule } from '@agm/core'; 
import { AgmOverlays } from "agm-overlays";
import { UnderDevelopmenSectionBlurComponent } from './under-development-section-blur/under-development-section-blur.component';
import { ContentBuilderComponent } from './content-builder/content-builder.component';
import { DocumentCreatorComponent } from './document-creator/document-creator.component';
import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { PropertyItemComponent } from './property-item/property-item.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { ClientJobCreatorComponent } from './client-job-creator/client-job-creator.component';
import { PropertiesToolbarComponent } from './properties-toolbar/properties-toolbar.component';
import { PropertiesSearchComponent } from './properties-search/properties-search.component';
import { CompareOverviewComponent } from './compare-overview/compare-overview.component';
import { RatingComponent } from './rating/rating.component';
import { PropertiesSearchResultsFiltersComponent } from './properties-search-results-filters/properties-search-results-filters.component';
import { PropertiesCarouselComponent } from './properties-carousel/properties-carousel.component';
import { TopProfessionalsComponent } from './top-professionals/top-professionals.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { CommentsComponent } from './comments/comments.component';
import { TestimonialsComponent } from './testimonials/testimonials.component'; 
import { OurAgentsComponent } from './our-agents/our-agents.component';
import { MissionComponent } from './mission/mission.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { HowPricingWorksComponent } from './how-pricing-works/how-pricing-works.component';
import { LogoComponent } from './logo/logo.component';
import { InstantMessagingComponent } from './instant-messaging/instant-messaging.component';
import { VendorEmployeeExpandableTableComponent } from './professional/vendor-employee-expandable-table/vendor-employee-expandable-table.component';
import { WorkOrderExpandableTableComponent } from './professional/work-order-expandable-table/work-order-expandable-table.component';
import { ActiveBidExpandableTableComponent } from './active-bid-expandable-table/active-bid-expandable-table.component';
import { VendorInvoiceExpandableTableComponent } from './professional/vendor-invoice-expandable-table/vendor-invoice-expandable-table.component';
import { VendorFollowOnExpandableTableComponent } from './professional/vendor-follow-on-expandable-table/vendor-follow-on-expandable-table.component';
import { VendorAssetWorkOrderExpandableTableComponent } from './professional/vendor-asset-work-order-expandable-table/vendor-asset-work-order-expandable-table.component';
import { VendorWorkOrderTableComponent } from './professional/vendor-work-order-table/vendor-work-order-table.component';
import { VendorDynamicDataTableComponent } from './professional/vendor-dynamic-data-table/vendor-dynamic-data-table.component'

import { VendorCalendarComponent } from './professional/vendor-calendar/vendor-calendar.component';
import { VendorWorkOrderCalendarComponent } from './professional/vendor-work-order-calendar/vendor-work-order-calendar.component';
import { ParallaxWoodsComponent } from './parallax-woods/parallax-woods.component';
import { AnimatedScenesComponent } from './animated-scenes/animated-scenes.component'
import { ComingSoonCountdownComponent } from './coming-soon-countdown/coming-soon-countdown.component';
import { KeyPlatformFeaturesComponent } from './key-platform-features/key-platform-features.component';
import { MeeseeksComponent } from './meeseeks/meeseeks.component'
import { MetaAtomComponent } from './meta_atom/meta_atom.component'
import { SittingDogAnimationComponent } from './sitting-dog-animation/sitting-dog-animation.component'

import { VendorPartRequestDialogComponent } from './professional/vendor-part-request-dialog/vendor-part-request-dialog.component'
import { VendorAddPartRequestDialogComponent } from './professional/vendor-add-part-request-dialog/vendor-add-part-request-dialog.component'
import { CustomShiftDialogComponent } from './professional/vendor-custom-shift-dialog/vendor-custom-shift-dialog.component';
import { VendorAddInventoryItemDialogComponent } from './professional/vendor-add-inventory-item-dialog/vendor-add-inventory-item-dialog.component';
import { VendorShiftSwapDialogComponent } from './professional/vendor-shift-swap-dialog/vendor-shift-swap-dialog.component';
import { VendorAddWorkOrderDialogComponent } from './professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';
import { VendorAddAssetWorkOrderDialogComponent } from './professional/vendor-add-asset-work-order-dialog/vendor-add-asset-work-order-dialog.component';
import { VendorWorkOrderDialogComponent } from './professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { VendorAddAssetDialogComponent } from './professional/vendor-add-asset-dialog/vendor-add-asset-dialog.component';
import { VendorAddCMMSLocationDialogComponent } from './professional/vendor-add-cmms-location-dialog/vendor-add-cmms-location-dialog.component';
import { InfoConfirmDialogComponent } from './info-confirm-dialog/info-confirm-dialog.component';
import { VerificationDialogComponent } from './professional/verification-dialog/verification-dialog.component';

import { VendorShiftCalendarComponent } from './professional/vendor-shift-calendar/vendor-shift-calendar.component';

import { VendorEfficiencyAndWorkloadChartComponent } from './professional/vendor-efficiency-and-workload-chart/vendor-efficiency-and-workload-chart.component';
import { VendorChartDoughnutComponent } from './professional/vendor-chart-doughnut/vendor-chart-doughnut.component';
import { VendorChartLineComponent } from './professional/vendor-chart-line/vendor-chart-line.component';
import { VendorChartBarComponent } from './professional/vendor-chart-bar/vendor-chart-bar.component';

import { VendorWorkOrderManagementComponent } from './professional/vendor-work-order-management/vendor-work-order-management.component';
import { AssetItemComponent } from './asset-item/asset-item.component'
import { InventoryItemComponent } from './inventory-item/inventory-item.component';
import { InventoryToolbarComponent } from './inventory-toolbar/inventory-toolbar.component';
import { AssetToolbarComponent } from './asset-toolbar/asset-toolbar.component';
import { LocalInvoiceCreatorComponent } from './local-invoice-creator/local-invoice-creator.component';
import { VendorWorkOrderBuilderComponent } from './professional/vendor-work-order-builder/vendor-work-order-builder.component';
import { VendorFeaturesComponent } from './professional/vendor-features/vendor-features.component';
import { CarouselSupportComponent } from './carousel-support/carousel-support.component';
import { CarouselSupportFeatureComponent } from './carousel-support-feature/carousel-support-feature.component';
import { VendorWorkOrderSchedulingWeekViewComponent } from './professional/vendor-work-order-scheduling-week-view/vendor-work-order-scheduling-week-view.component';
import { VendorShiftSwapRequestComponent } from './professional/vendor-shift-swap-request/vendor-shift-swap-request.component';
import { VendorShiftSwapReviewComponent } from './professional/vendor-shift-swap-review/vendor-shift-swap-review.component';
import { VendorServiceAreaMapComponent } from './professional/vendor-service-area-map/vendor-service-area-map.component';

import { JobListingItemComponent } from './job-listing-item/job-listing-item.component';
import { JobListingToolbarComponent } from './job-listing-toolbar/job-listing-toolbar.component';
import { JobListingSearchComponent } from './job-listing-search/job-listing-search.component';
import { JobListingSearchResultsFiltersComponent } from './job-listing-search-results-filters/job-listing-search-results-filters.component';
import { JobListingCarouselComponent } from './job-listing-carousel/job-listing-carousel.component';
import { DayNightSwitchComponent } from './day-night-switch/day-night-switch.component';
import { VendorPartAttachmentComponent } from './professional/vendor-part-attachment/vendor-part-attachment.component';
import { VendorPartAttacherDialogComponent } from './professional/vendor-part-attacher-dialog/vendor-part-attacher-dialog.component'
import { VendorInvoiceBaseComponent } from './professional/vendor-invoice-base/vendor-invoice-base.component'
import { VendorServiceTrackerComponent } from './professional/vendor-service-tracker/vendor-service-tracker.component'
import { VendorCmmsItemRequestComponent } from './professional/vendor-cmms-item-request/vendor-cmms-item-request.component'
import { VendorLinkedAccountRequestComponent } from './professional/vendor-linked-account-request/vendor-linked-account-request.component'
import { VendorWorkAuditTrackerComponent } from './professional/vendor-work-audit-tracker/vendor-work-audit-tracker.component'
import { VendorWorkAuditTrackerDialogComponent } from './professional/vendor-work-audit-tracker-dialog/vendor-work-audit-tracker-dialog.component'
import { VendorEmployeeLinkedManagementDialogComponent } from './professional/vendor-employee-linked-management-dialog/vendor-employee-linked-management-dialog.component'
import { RegisterCompanyComponent } from './register-company/register-company.component'
import { RegisterCompanyDialogComponent } from './register-company-dialog/register-company-dialog.component'
import { MovingTruckAnimationComponent } from './moving-truck-animation/moving-truck-animation.component'
import { SkiLiftAnimationComponent } from './ski-lift-landscape-animation/ski-lift-landscape-animation.component'
import { VendorWorkOrderGanttComponent } from './professional/vendor-work-order-gantt/vendor-work-order-gantt.component'
import { WhyWeStandOutComponent } from './why-we-stand-out/why-we-stand-out.component'
import { VendorAutomationDialogComponent } from './professional/vendor-automation-dialog/vendor-automation-dialog.component'
import { EmailVerifiedComponent } from './email-verified/email-verified.component'
import { PaymentVerifiedComponent } from './payment-verified/payment-verified.component'
import { MembershipPortalComponent } from './membership-portal/membership-portal.component'
import { MenuGooeyComponent } from './menu-gooey/menu-gooey.component'
import { VendorWorkOrderQuickActionDialogComponent } from './professional/vendor-work-order-quick-action-dialog/vendor-work-order-quick-action-dialog.component'
import { VendorInvoiceGeneratorDialogComponent } from './professional/vendor-invoice-generator-dialog/vendor-invoice-generator-dialog.component'
import { VendorDialPadComponent } from './professional/vendor-dial-pad/vendor-dial-pad.component'
import { VendorTextChatComponent } from './professional/vendor-text-chat/vendor-text-chat.component'
import { VendorAIMessagingComponent } from './professional/vendor-ai-messaging/vendor-ai-messaging.component'
import { VendorCRMCustomerViewerComponent } from './professional/vendor-crm-customer-viewer/vendor-crm-customer-viewer.component'
import { VendorPhoneHoldComponent } from './professional/vendor-phone-hold/vendor-phone-hold.component'
import { VendorContactComponent } from './professional/vendor-contact/vendor-contact.component'
import { VendorContactDialogComponent } from './professional/vendor-contact-dialog/vendor-contact-dialog.component'
import { VendorContactListComponent } from './professional/vendor-contact-list/vendor-contact-list.component'
import { VendorRecentCallLogComponent } from './professional/vendor-recent-call-log/vendor-recent-call-log.component'
import { VendorCRMAddDealDialogComponent } from './professional/vendor-crm-add-deal-dialog/vendor-crm-add-deal-dialog.component'
import { CRMDealComponent } from './professional/vendor-crm-deal/vendor-crm-deal.component'
import { NewDealActivityComponent } from './new-deal-activity/new-deal-activity.component'
import { VendorCRMActivityDialogComponent } from './professional/vendor-crm-activity-dialog/vendor-crm-activity-dialog.component'
import { VendorRevenueSubscriptionComponent } from './professional/vendor-revenue-subscription/vendor-revenue-subscription.component'
import { VendorRevenueDirectComponent } from './professional/vendor-revenue-direct/vendor-revenue-direct.component'
import { VendorCreatePipelineDialogComponent } from './professional/vendor-create-pipeline-dialog/vendor-create-pipeline-dialog.component'
import { VendorCRMEditPipelinesComponent } from './professional/vendor-crm-edit-pipelines/vendor-crm-edit-pipelines.component'
import { VendorFormComponent } from './professional/vendor-form/vendor-form.component'
import { VendorFormTemplatesComponent } from './professional/vendor-form-templates/vendor-form-templates.component'
import { VendorFormOverviewComponent } from './professional/vendor-form-overview/vendor-form-overview.component'
import { VendorFormCreateDocumentDialogComponent } from './professional/vendor-form-create-document-dialog/vendor-form-create-document-dialog.component'
import { VendorTeamMenuComponent } from './professional/vendor-team-menu/vendor-team-menu.component';
import { VendorAssignStaffComponent } from './professional/vendor-assign-staff/vendor-assign-staff.component';
import { VendorWorkOrderFormDialogComponent } from './professional/vendor-work-order-form-dialog/vendor-work-order-form-dialog.component';
import { VendorWorkOrderFormComponent } from './professional/vendor-work-order-form/vendor-work-order-form.component';
import { VendorMultiPurposeCalendarComponent } from './professional/vendor-multi-purpose-calendar/vendor-multi-purpose-calendar.component'
import { VendorCalendarEventPanelComponent } from './professional/vendor-calendar-event-panel/vendor-calendar-event-panel.component';
import { VendorAutomationTagComponent } from './professional/vendor-automation-tag/vendor-automation-tag.component';
import { VendorObjectMessagingComponent } from './professional/vendor-object-messaging/vendor-object-messaging.component'
import { VendorCrossModuleReportComponent } from './professional/vendor-cross-module-report/vendor-cross-module-report.component'
import { VendorCrossModuleReportCreationComponent } from './professional/vendor-cross-module-report-creation/vendor-cross-module-report-creation.component'
import { VendorCrossModuleReportContainerComponent } from './professional/vendor-cross-module-report-container/vendor-cross-module-report-container.component'
import { PartnerLicenseDialogComponent } from './partner-license-company-dialog/partner-license-company-dialog.component'
import { PartnerLicenseCompanyComponent } from './partner-license-company/partner-license-company.component'
import { TermsConditionsPartnerLicenseComponent } from './terms-conditions-partner-license/terms-conditions-partner-license.component'
import { VendorFormBuilderDialogComponent } from './professional/vendor-form-builder-dialog/vendor-form-builder-dialog.component'
import { VendorProjectBudgetComponent } from './professional/vendor-project-budget/vendor-project-budget.component'
import { VendorProjectBudgetDialogComponent } from './professional/vendor-project-budget-dialog/vendor-project-budget-dialog.component'
import { VendorCustomServicesComponent } from './professional/vendor-custom-services/vendor-custom-services.component'
import { VendorSearchComponent } from './professional/vendor-search/vendor-search.component';
import { VendorProjectDialogComponent } from './professional/vendor-project-dialog/vendor-project-dialog.component';
import { LicenseManagerComponent } from './professional/vendor-license-manager/vendor-license-manager.component';
import { InsuranceManagerComponent } from './professional/vendor-insurance-manager/vendor-insurance-manager.component';
import { HexVideoClipComponent } from './hex-video-clip/hex-video-clip.component';
import { PlatformModuleFeaturesComponent } from './platform-module-features/platform-module-features.component';
import { PlatformModulesComponent } from './platform-modules/platform-modules.component';

import { GoldenLayoutBaseComponent } from './golden-layout-base/golden-layout-base.component';
import { FileUploadComponent } from './file-upload/file-upload.component';

// VENDOR COMPONENTS
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryItemDetailComponent } from './inventory-item-detail/inventory-item-detail.component';
import { VendorActivityFocusComponent } from './professional/vendor-activity-focus/vendor-activity-focus.component';



export const routes = [
  { path: 'inventory_detail/:inventory_id', component: InventoryItemDetailComponent },
  { path: 'job/:company_id/:work_order_id', component: VendorWorkAuditTrackerComponent },
  // { path: 'create/document/:template_id', component: VendorFormComponent },

  // { path: 'document/:company_id/:document_id', component: VendorFormComponent },
  // { path: 'document/:company_id/:document_id/:code', component: VendorFormComponent },

  { path: 'emailverification/:user_id/:email/:code', component: EmailVerifiedComponent },
  // { path: 'sign/document/:document_id/:security_code', component: VendorFormComponent }
];

@NgModule({

  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    FormsModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NgbModule,
    ChartsModule,
    DragDropModule,
    PrebuiltComponentsModule,
    DragToSelectModule.forRoot(),
    QuillModule.forRoot(),
    NgChatModule,
    ColorPickerModule,
    NgxMaterialTimepickerModule,
    InputFileModule,
    AgmCoreModule,
    AgmOverlays
  ],

  exports: [
    RouterModule,
    ReactiveFormsModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule, 
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    PropertyItemComponent,
    LoadMoreComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    CompareOverviewComponent,
    RatingComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    TopProfessionalsComponent,
    GetInTouchComponent,
    CommentsComponent,
    TestimonialsComponent,   
    OurAgentsComponent, 
    MissionComponent, 
    OurServicesComponent,
    HowItWorksComponent,
    HowPricingWorksComponent,
    VendorEmployeeExpandableTableComponent,
    WorkOrderExpandableTableComponent,
    ActiveBidExpandableTableComponent,
    VendorInvoiceExpandableTableComponent,
    VendorFollowOnExpandableTableComponent,
    VendorAssetWorkOrderExpandableTableComponent,
    VendorCalendarComponent,
    VendorWorkOrderCalendarComponent,
    VendorPartRequestDialogComponent,
    VendorAddPartRequestDialogComponent,
    CustomShiftDialogComponent,
    VendorAddInventoryItemDialogComponent,
    VendorShiftSwapDialogComponent,
    VendorAddWorkOrderDialogComponent,
    VendorAddAssetWorkOrderDialogComponent,
    VendorWorkOrderDialogComponent,
    VendorAddAssetDialogComponent,
    VendorAddCMMSLocationDialogComponent,
    InfoConfirmDialogComponent,
    VerificationDialogComponent,
    VendorShiftCalendarComponent,
    VendorEfficiencyAndWorkloadChartComponent,
    VendorChartDoughnutComponent,
    VendorChartLineComponent,
    VendorChartBarComponent,
    VendorWorkOrderManagementComponent,
    DragDropModule,
    UnderDevelopmenSectionBlurComponent,
    ContentBuilderComponent,
    DocumentCreatorComponent,
    InstantMessagingComponent,
    InventoryItemComponent,
    AssetItemComponent,
    InventoryToolbarComponent,
    AssetToolbarComponent,
    LocalInvoiceCreatorComponent,
    VendorWorkOrderBuilderComponent,
    ClientJobCreatorComponent,
    JobListingItemComponent,
    JobListingToolbarComponent,
    JobListingSearchComponent,
    JobListingSearchResultsFiltersComponent,
    JobListingCarouselComponent,
    VendorFeaturesComponent,
    CarouselSupportComponent,
    CarouselSupportFeatureComponent,
    VendorWorkOrderSchedulingWeekViewComponent,
    VendorShiftSwapRequestComponent,
    VendorShiftSwapReviewComponent,
    ParallaxWoodsComponent,
    AnimatedScenesComponent,
    ComingSoonCountdownComponent,
    KeyPlatformFeaturesComponent,
    MeeseeksComponent,
    MetaAtomComponent,
    VendorWorkOrderTableComponent,
    VendorDynamicDataTableComponent,
    VendorServiceAreaMapComponent,
    DayNightSwitchComponent,
    VendorPartAttachmentComponent,
    VendorPartAttacherDialogComponent,
    InventoryComponent,
    InventoryItemDetailComponent,
    VendorInvoiceBaseComponent,
    VendorServiceTrackerComponent,
    VendorCmmsItemRequestComponent,
    VendorLinkedAccountRequestComponent,
    VendorWorkAuditTrackerComponent,
    VendorWorkAuditTrackerDialogComponent,
    VendorWorkOrderGanttComponent,
    VendorEmployeeLinkedManagementDialogComponent,
    RegisterCompanyComponent,
    RegisterCompanyDialogComponent,
    MovingTruckAnimationComponent,
    SkiLiftAnimationComponent,
    WhyWeStandOutComponent,
    VendorAutomationDialogComponent,
    EmailVerifiedComponent,
    MenuGooeyComponent,
    VendorWorkOrderQuickActionDialogComponent,
    VendorInvoiceGeneratorDialogComponent,
    VendorDialPadComponent,
    VendorTextChatComponent,
    VendorAIMessagingComponent,
    VendorCRMCustomerViewerComponent,
    VendorPhoneHoldComponent,
    VendorContactComponent,
    VendorContactDialogComponent,
    VendorContactListComponent,
    VendorRecentCallLogComponent,
    VendorCRMAddDealDialogComponent,
    SittingDogAnimationComponent,
    CRMDealComponent,
    NewDealActivityComponent,
    VendorCRMActivityDialogComponent,
    VendorRevenueSubscriptionComponent,
    VendorRevenueDirectComponent,
    VendorCreatePipelineDialogComponent,
    VendorCRMEditPipelinesComponent,
    VendorFormComponent,
    VendorFormTemplatesComponent,
    VendorFormOverviewComponent,
    VendorFormCreateDocumentDialogComponent,
    VendorTeamMenuComponent,
    VendorAssignStaffComponent,
    VendorWorkOrderFormDialogComponent,
    VendorWorkOrderFormComponent,
    VendorMultiPurposeCalendarComponent,
    VendorCalendarEventPanelComponent,
    VendorAutomationTagComponent,
    VendorObjectMessagingComponent,
    VendorCrossModuleReportComponent,
    VendorCrossModuleReportCreationComponent,
    VendorCrossModuleReportContainerComponent,
    GoldenLayoutBaseComponent,
    FileUploadComponent,
    PartnerLicenseDialogComponent,
    PartnerLicenseCompanyComponent,
    TermsConditionsPartnerLicenseComponent,
    VendorFormBuilderDialogComponent,
    VendorProjectBudgetComponent,
    VendorProjectBudgetDialogComponent,
    VendorCustomServicesComponent,
    VendorSearchComponent,
    VendorActivityFocusComponent,
    VendorProjectDialogComponent,
    LicenseManagerComponent,
    InsuranceManagerComponent,
    HexVideoClipComponent,
    PlatformModuleFeaturesComponent,
    PlatformModulesComponent,
    PaymentVerifiedComponent,
    MembershipPortalComponent
  ],

  declarations: [ 
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    PropertyItemComponent,
    LoadMoreComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    CompareOverviewComponent,
    RatingComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    TopProfessionalsComponent,
    GetInTouchComponent,
    CommentsComponent,
    TestimonialsComponent,   
    OurAgentsComponent, 
    MissionComponent, 
    OurServicesComponent,
    HowItWorksComponent,
    HowPricingWorksComponent,
    VendorEmployeeExpandableTableComponent,
    WorkOrderExpandableTableComponent,
    ActiveBidExpandableTableComponent,
    VendorInvoiceExpandableTableComponent,
    VendorFollowOnExpandableTableComponent,
    VendorAssetWorkOrderExpandableTableComponent,
    VendorCalendarComponent,
    VendorWorkOrderCalendarComponent,
    VendorPartRequestDialogComponent,
    VendorAddPartRequestDialogComponent,
    CustomShiftDialogComponent,
    VendorAddInventoryItemDialogComponent,
    VendorShiftSwapDialogComponent,
    VendorAddWorkOrderDialogComponent,
    VendorAddAssetWorkOrderDialogComponent,
    VendorWorkOrderDialogComponent,
    VendorAddAssetDialogComponent,
    VendorAddCMMSLocationDialogComponent,
    InfoConfirmDialogComponent,
    VerificationDialogComponent,
    VendorShiftCalendarComponent,
    VendorEfficiencyAndWorkloadChartComponent,
    VendorChartDoughnutComponent,
    VendorChartLineComponent,
    VendorChartBarComponent,
    VendorWorkOrderManagementComponent,
    UnderDevelopmenSectionBlurComponent,
    ContentBuilderComponent,
    DocumentCreatorComponent,
    InstantMessagingComponent,
    InventoryItemComponent,
    AssetItemComponent,
    InventoryToolbarComponent,
    AssetToolbarComponent,
    LocalInvoiceCreatorComponent,
    VendorWorkOrderBuilderComponent,
    ClientJobCreatorComponent,
    JobListingItemComponent,
    JobListingToolbarComponent,
    JobListingSearchComponent,
    JobListingSearchResultsFiltersComponent,
    JobListingCarouselComponent,
    VendorFeaturesComponent,
    CarouselSupportComponent,
    CarouselSupportFeatureComponent,
    VendorWorkOrderSchedulingWeekViewComponent,
    VendorShiftSwapRequestComponent,
    VendorShiftSwapReviewComponent,
    ParallaxWoodsComponent,
    AnimatedScenesComponent,
    ComingSoonCountdownComponent,
    KeyPlatformFeaturesComponent,
    MeeseeksComponent,
    MetaAtomComponent,
    VendorWorkOrderTableComponent,
    VendorDynamicDataTableComponent,
    VendorServiceAreaMapComponent,
    DayNightSwitchComponent,
    VendorPartAttachmentComponent,
    VendorPartAttacherDialogComponent,
    InventoryComponent,
    InventoryItemDetailComponent,
    VendorInvoiceBaseComponent,
    VendorServiceTrackerComponent,
    VendorCmmsItemRequestComponent,
    VendorLinkedAccountRequestComponent,
    VendorWorkAuditTrackerComponent,
    VendorWorkAuditTrackerDialogComponent,
    VendorWorkOrderGanttComponent,
    VendorEmployeeLinkedManagementDialogComponent,
    RegisterCompanyComponent,
    RegisterCompanyDialogComponent,
    MovingTruckAnimationComponent,
    SkiLiftAnimationComponent,
    WhyWeStandOutComponent,
    VendorAutomationDialogComponent,
    EmailVerifiedComponent,
    MenuGooeyComponent,
    VendorWorkOrderQuickActionDialogComponent,
    VendorInvoiceGeneratorDialogComponent,
    VendorDialPadComponent,
    VendorTextChatComponent,
    VendorAIMessagingComponent,
    VendorCRMCustomerViewerComponent,
    VendorPhoneHoldComponent,
    VendorContactComponent,
    VendorContactDialogComponent,
    VendorContactListComponent,
    VendorRecentCallLogComponent,
    VendorCRMAddDealDialogComponent,
    SittingDogAnimationComponent,
    CRMDealComponent,
    NewDealActivityComponent,
    VendorCRMActivityDialogComponent,
    VendorRevenueSubscriptionComponent,
    VendorRevenueDirectComponent,
    VendorCreatePipelineDialogComponent,
    VendorCRMEditPipelinesComponent,
    VendorFormComponent,
    VendorFormTemplatesComponent,
    VendorFormOverviewComponent,
    VendorFormCreateDocumentDialogComponent,
    VendorTeamMenuComponent,
    VendorAssignStaffComponent,
    VendorWorkOrderFormDialogComponent,
    VendorWorkOrderFormComponent,
    VendorMultiPurposeCalendarComponent,
    VendorCalendarEventPanelComponent,
    VendorAutomationTagComponent,
    VendorObjectMessagingComponent,
    VendorCrossModuleReportComponent,
    VendorCrossModuleReportCreationComponent,
    VendorCrossModuleReportContainerComponent,
    GoldenLayoutBaseComponent,
    FileUploadComponent,
    PartnerLicenseDialogComponent,
    PartnerLicenseCompanyComponent,
    TermsConditionsPartnerLicenseComponent,
    VendorFormBuilderDialogComponent,
    VendorProjectBudgetComponent,
    VendorProjectBudgetDialogComponent,
    VendorCustomServicesComponent,
    VendorSearchComponent,
    VendorActivityFocusComponent,
    VendorProjectDialogComponent,
    LicenseManagerComponent,
    InsuranceManagerComponent,
    HexVideoClipComponent,
    PlatformModuleFeaturesComponent,
    PlatformModulesComponent,
    PaymentVerifiedComponent,
    MembershipPortalComponent
  ],

  bootstrap: [
    
  ],

  schemas: [
    NO_ERRORS_SCHEMA
  ],

  entryComponents:[
    CompareOverviewComponent
  ],
  providers:[
    { 
      provide: PERFECT_SCROLLBAR_CONFIG, 
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG 
    }
  ]
})
export class SharedModule { }