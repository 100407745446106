import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BusinessConsultation } from 'src/app/data-models/businessConsultation';
import { AiChatMessage } from 'src/app/data-models/aiChatMessage';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { AiChatService } from 'src/app/services/vendor/ai-chat.service';
import { NotificationService } from 'src/app/services/vendor/notification.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { AnimationType } from "../../carousel-support/carousel-support.animations";
import { CarouselSupportComponent } from "../../carousel-support/carousel-support.component";
import { Profile } from 'src/app/data-models/models';

export enum ObjectTypes {
  WORK_ORDER = 0,
  DEAL = 1,
  PIPE = 2,
  INVOICE = 3
}

@Component({
  selector: 'app-vendor-ai-messaging',
  templateUrl: 'vendor-ai-messaging.component.html',
  styleUrls: ['vendor-ai-messaging.component.scss']
})

export class VendorAIMessagingComponent implements OnInit {
  @ViewChild('messageHistoryContainer') messageHistoryContainerRef: any;  

  private profile: Profile = this.navigatorService.getCurrentProfile();

  @Input() messages: Array<AiChatMessage> = [{
    role: "assistant",
    content: `Hey there ${this.profile?.first_name}, what can I help you with today?`
  }];

  @Input() taggedObject: any = null;
  @Input() objectType: number = null;

  @Input() styleBackground: string = "#000000";
  @Input() styleColor: string = "#ffffff";
  @Input() styleHeight: string = "1.25em";
  @Input() styleWidth: string = "1.25em";
  @Input() styleDisplayTitle: boolean = false;
  @Input() styleDisplayTooltip: boolean = false;
  @Input() styleLeftExpand: boolean = false;

  private keyTypes: any = keyTypesEnum;
  public objTypes: any = ObjectTypes;

  public profileId: string = null;
  public profileImg: string | null = null;
  public customerViewer: boolean = false;

  public textMessage: string = "";
  public userMessage: string = "";

  public teamChatExpanded: boolean = false;

  public randomName: string;
  public randomId: string;

  constructor(
    public snackBar: MatSnackBar, 
    private navigatorService: NavigatorService,
    private aiChatService: AiChatService,
    private imageHandler: ImageHandlerService,
    private twilioHandler: TwilioHandlerService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.profileId = this.navigatorService.getProfileId();
    this.getProfileImg().then((img: string | null) => {
      this.profileImg = img;
    });

    this.randomName = 'input_' + Math.random().toString(36).substr(2, 10);
    this.randomId = 'id_' + Math.random().toString(36).substr(2, 10);
  }

  ngAfterViewInit() {

    // Auto scroll to the bottom of the message panel
    this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;
  }

  public containerClick(): void {

  }

  private async getProfileImg(): Promise<string | null> {

    return await this.imageHandler.getPresignedURL(this.profile.image);

  } 

  public getRandomName(): string {
    return 'input_' + Math.random().toString(36).substr(2, 10);
  }
  
  public getRandomId(): string {
    return 'id_' + Math.random().toString(36).substr(2, 10);
  }

  public autoResize(textarea: HTMLTextAreaElement): void {
    // textarea.style.height = 'auto'; // Reset height first
    // textarea.style.height = Math.min(textarea.scrollHeight, 72) + 'px'; // Max height of 3 rows (72px)
  }
  

  public updateUserMessage(message: string): void {
    this.userMessage = message;
  }

  public async sendMessage(): Promise<void> {

    console.log("Sending AI Message");

    const sendMsg: string = this.userMessage;

    const consultation: BusinessConsultation = {
      userId: this.navigatorService.getProfileId(),
      breadcrumbTrail: {
        module: "",
        subpage: ""
      },
      chatHistory: this.messages
    };

    this.userMessage = "";

    this.messages.push({
      role: "user",
      content: sendMsg
    });

    const aiResponse: string = await this.aiChatService.sendAiChatMsg(sendMsg, consultation);

    this.messages.push({
      role: "assistant",
      content: aiResponse
    });

    this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;

  }

}