import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutomationsService } from '../../../services/vendor/automations.service'
import { AppService } from 'src/app/app.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Automation, AutomationCategory } from 'src/app/data-models/models';
import { PhoneStatusEnum, TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { title } from 'process';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { SafeUrl } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';
import { PlatformService } from 'src/app/services/vendor/platform.service';

@Component({
  selector: 'app-vendor-toolbar1',
  templateUrl: './vendor-toolbar1.component.html',
  styleUrls: ['./vendor-toolbar1.component.scss'],
})
export class VendorToolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() aiIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() aiChatClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() automationPanelClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() subMenu: boolean = false;

  private advancedVersion: string = "2.0.0";
  public authorizedLevelV2Features: boolean = this.platformService.versionChecker(this.advancedVersion);

  public phoneStatusCategories: any = PhoneStatusEnum;
  public phoneStatus: number = this.phoneStatusCategories.AUTHORIZING;
  public phoneStatusTitle: string = "";

  public isAIMenuVisible: boolean = false;
  public isAiChatVisible: boolean = false;
  public isAutomationMenuVisible: boolean = false;

  public companyIdAssigned: string = null;
  public companyLogo: SafeUrl = null;

  public automations: Array<Automation> = [];
  
  constructor(
    private platformService: PlatformService,
    public appService: AppService, 
    private automationService: AutomationsService, 
    private twilioHandler: TwilioHandlerService,
    private navigatorService: NavigatorService,
    private imageHandler: ImageHandlerService) { }

  ngOnInit() {
    this.automationsSubscribe();
    this.getAutomations();
    this.monitorCompanyId();
    this.listeners();

    this.initializeLogo();
    this.initializePhoneVariables();
  }

  private initializePhoneVariables(): void {

    this.phoneStatus = this.twilioHandler.getCallStatus();
    this.phoneStatusTitle = this.translatePhoneStatusTitle(this.phoneStatus);

  }


  private initializeLogo(): void {

    this.getImg(this.navigatorService.getCompanyLogo());

  }


  private listeners(): void {

    this.twilioHandler.getCallStatusTrigger().subscribe( (status: number) => {
      this.phoneStatus = status;
      this.phoneStatusTitle = this.translatePhoneStatusTitle(this.phoneStatus);
    });

    this.navigatorService.getCompanyLogoObservable().subscribe( (imgId: string) => {
      this.getImg(imgId);
    });

  }

  private getImg(imgId: string): void {

    if(imgId != undefined && imgId.length > 0) {

      this.imageHandler.getAsyncFile(imgId).then( (img: SafeUrl) => {

        if(img != undefined && img != null && img != "Failed") {
          this.companyLogo = img;

          // Temp Disabled White Label Logo
          this.companyLogo = null;
        }

      });

    }

  }

  private monitorCompanyId(): void {
    let self = this;

    setTimeout(function() {
      self.companyIdAssigned = self.navigatorService.getCompanyId();
      self.monitorCompanyId();
    }, 1000);

  }

  public translatePhoneStatusTitle(category: number): string {
    let title: string = "";

    switch(category) {

      case this.phoneStatusCategories.READY:
        title = "Ready";
        break;

      case this.phoneStatusCategories.INCOMING_CALL:
        title = "Incoming";
        break;

      case this.phoneStatusCategories.OUTBOUND_CALL:
        title = "Ringing";
        break;

      case this.phoneStatusCategories.CONFERENCE_CALL:
        title = "Conference";
        break;

      case this.phoneStatusCategories.CONNECTED_CALL:
        title = "Connected";
        break;

      case this.phoneStatusCategories.DISCONNECTED_CALL:
        title = "Ready";
        break;

      case this.phoneStatusCategories.VIDEO:
        title = "Video";
        break;

      case this.phoneStatusCategories.HOLD:
        title = "Hold";
        break;

      case this.phoneStatusCategories.ERROR:
        title = "Error";
        break;

      case this.phoneStatusCategories.AUTHORIZING:
        title = "Authorizing";
        break;

      default:
        title = "Authorizing";
        break;

    }

    return title;
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }

  public automationPanelToggle() {
    this.automationPanelClick.emit();
    console.log("Automation Panel Button Clicked!");

    if(this.isAutomationMenuVisible) {
      this.isAutomationMenuVisible = false;
    } else {
      this.isAutomationMenuVisible = true;
    }
  }

  public aiToggle() {
    if(this.isAIMenuVisible) {
      this.isAIMenuVisible = false;
    } else {
      this.isAIMenuVisible = true;
    }

    this.aiIconClick.emit(this.isAIMenuVisible);
    console.log("AI Button Clicked!");
  }

  public aiChatToggle() {
    this.isAiChatVisible = this.isAiChatVisible ? false : true;

    this.aiChatClick.emit(this.isAiChatVisible);
    console.log("AI Chat Clicked!");
  }

  private automationsSubscribe(): void {
    
    this.automationService.automationsBroadcaster().subscribe( (automations: Array<Automation>) => {

      if(automations != undefined && automations != null) {
        this.automations = automations;
      }

    });

  }

  getAutomations(): void {

    this.navigatorService.getAsyncAutomations().then( (automations: Array<Automation>) => {

      if(automations != undefined && automations != null) {
        this.automations = automations;
      }

    });

  }

  public addAutomation() {
    this.automationService.openAddWorkOrderDialog();
  }

  public editAutomation(automation: Automation) {
    this.automationService.openEditWorkOrderDialog(automation);
  }
}