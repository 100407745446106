import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationNotificationMessage, AutomationTag, Budget, CompanyProfile, CRMDeal, DocumentedDoc, DocumentedImage, FileObject, FormattedWorkOrderAuditLog, GroupPermissions, Profile, StatusTag, StripePaymentDetails, WOInvoice, WorkOrder, WorkOrderCustomField, WorkOrderQuickAction } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { workOrderStatusTypes, keyTypesEnum, NavigatorService, notificationCategoriesEnum, notificationStatusEnum } from 'src/app/services/vendor/navigator.service';
import { WorkOrderAuditService } from '../../../services/vendor/work-order-audit.service';

import pdfMake from "pdfmake/build/pdfmake";
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { TagTypes } from 'src/app/vendor/admin-account/automation-tags/automation-tags.component';
import { NotificationService } from 'src/app/services/vendor/notification.service';
import { VendorWorkOrderFormDialogComponent } from '../../professional/vendor-work-order-form-dialog/vendor-work-order-form-dialog.component';
import { VendorProjectBudgetDialogComponent } from '../vendor-project-budget-dialog/vendor-project-budget-dialog.component';
import { VendorWorkOrderQuickActionDialogComponent } from '../vendor-work-order-quick-action-dialog/vendor-work-order-quick-action-dialog.component';
import { quickActionCategoryEnum } from '../../menu-gooey/menu-gooey.component';
import { environment } from 'src/environments/environment';
import { MAT_DATE_FORMATS, MatDateFormats, DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { WorkOrdersService } from 'src/app/services/vendor/work-orders.service';

export enum auditPageEnum {
  ACTIVITY = 0,
  MESSAGING = 1,
  BILLING = 2,
  DOCUMENTS = 3,
  STATUS = 4,
  TEAM_CHAT = 5,
  WORK_ORDER = 6
}

export enum notificationMethodsEnum {
  SMS = 0,
  EMAIL = 1
}

export enum DetailEnum {
  DESCRIPTION = 0,
  DEAL_SCOPE = 1
}

export enum DocumentTypesEnum {
  DOCUMENTS = 0,
  IMGS = 1,
  DEAL = 2
}

export enum TeamViews {
  ASSIGNED = 0,
  ADD_TEAM = 1,
  ADD_EMPLOYEE = 2
}

export enum TimePickerTypes {
  SCHEDULED = 0,
  EST_COMPLETE = 1
}

@Component({
  selector: 'app-vendor-work-audit-tracker',
  templateUrl: 'vendor-work-audit-tracker.component.html',
  styleUrls: ['vendor-work-audit-tracker.component.scss']
})
export class VendorWorkAuditTrackerComponent implements OnInit {
  @ViewChild('messageHistoryContainer') messageHistoryContainerRef: any;  
  @Input() workOrder: WorkOrder = null;

  public exampleHeader = ExampleHeader;

  public mapKey: string = environment.googleMapEmbeddedAPI;
  public mapAddress: string = "";
  public deal: CRMDeal = null;
  
  private workOrderDialogRef: MatDialogRef<VendorWorkOrderFormDialogComponent>;
  private projectBudgetDialogRef: MatDialogRef<VendorProjectBudgetDialogComponent>;
  private workOrderQuickActionDialogRef: MatDialogRef<VendorWorkOrderQuickActionDialogComponent>;

  public timePickerTypes: any = TimePickerTypes;
  public timePickerFocus: number = this.timePickerTypes.SCHEDULED;

  public emptyStart: any = null;
  public emptyEnd: any = null;

  public dateScheduled: Date;
  public endDateScheduled: Date;

  private autoMinuteAdjusted: boolean = false;
  public timeStretch: number = 0;
  public startTimeScheduled: any;
  public endTimeScheduled: any;
  public viewableDateScheduled: string;

  auditTrail: Array<FormattedWorkOrderAuditLog> = [];
  mapMarkers: any = [];

  public quickActionCategories: any = quickActionCategoryEnum;
  private notificationCategories: any = notificationCategoriesEnum;
  private notificationStatus: any = notificationStatusEnum;
  public teamViews: any = TeamViews;

  public assignedStaff: Array<Profile> = [];
  public assignedTeams: Array<GroupPermissions> = [];
  public groups: Array<GroupPermissions> = [];

  public filteredEmployeeOptions: Array<string> = [];
  public filteredEmployeeProfiles: Array<Profile> = [];
  private employeeLookupText: string = "";
  private focusTeamLookup: string = "";

  public teamFocusView: number = this.teamViews.ASSIGNED;

  public tagTypes: any = TagTypes;
  public automationTags: Array<AutomationTag> = [];
  public assignedAutomationTags: Array<AutomationTag> = [];
  public statusTags: Array<AutomationTag> = [];
  public statusTag: AutomationTag = null;
  public statusTagsPanelExpand: boolean = false;
  public addStaffPanel: boolean = false;
  public tagsPanelExpand: boolean = false;
  public summaryEdit: boolean = false;
  public customerEdit: boolean = false;
  public customFieldsEdit: boolean = false;


  private companyId: string = null;
  private workOrderId: string = null;
  public customerViewer: boolean = true;

  public auditLogTypes: any = this.navigatorService.getJobAuditTypes();
  public workOrderStatusCodes: any = this.navigatorService.getStatusOptions();
  private keyTypes: any = keyTypesEnum;
  public jobStatusTypes: any = workOrderStatusTypes;
  public auditPages: any = auditPageEnum;
  public notificationMethods: any = notificationMethodsEnum
  public detailTypes: any = DetailEnum
  public documentTypes: any = DocumentTypesEnum
  public companyProfile: CompanyProfile = null;
  public companyName: string = "";

  public imageDeleteAction: boolean = false;
  public jobAuditFocus: number = null;
  public documentAuditFocus: number = null;
  public dealAuditFocus: number = null;

  public jobImages: Array<DocumentedImage> = [];
  public documents: Array<DocumentedDoc> = [];

  // public jobImages: Array<DocumentedImage> = [
  //   {
  //     id: this.navigatorService.generateKey(),
  //     imgs: [ { id: "132a8a5967e6e23ace1424b6" }, { id: "2e5bd9548336cbcb865a5884" }, { id: "325dda3213930d7a4158ce45" } ],
  //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //     created_date: new Date(),
  //     created_by: this.navigatorService.getProfileId()
  //   },
  //   {
  //     id: this.navigatorService.generateKey(),
  //     imgs: [ { id: "90b467648ae393bc5701bb3b" }, { id: "5252b3f84f4c910d26463483" }, { id: "58d0e502a4f3a8da8e9e433e" }, { id: "64e57734e69b5e2b3cae6f31" }, { id: "6c010e21c10444f1b0d42b3c" }, { id: "6e0cc1252eec2202a7d0912d" }, { id: "132a8a5967e6e23ace1424b6" }, { id: "2e5bd9548336cbcb865a5884" }, { id: "325dda3213930d7a4158ce45" } ],
  //     desc: "Donec volutpat libero quis urna cursus facilisis non non ante. Donec pharetra lectus sed neque dignissim, ac ultricies eros sagittis",
  //     created_date: new Date(),
  //     created_by: this.navigatorService.getProfileId()
  //   },
  //   {
  //     id: this.navigatorService.generateKey(),
  //     imgs: [ { id: "58d0e502a4f3a8da8e9e433e" }, { id: "64e57734e69b5e2b3cae6f31" }, { id: "6c010e21c10444f1b0d42b3c" }, { id: "6e0cc1252eec2202a7d0912d" } ],
  //     desc: "Suspendisse malesuada tincidunt sem",
  //     created_date: new Date(),
  //     created_by: this.navigatorService.getProfileId()
  //   }
  // ];

  // public documents: Array<DocumentedDoc> = [
    
  //   {
  //     id: this.navigatorService.generateKey(),
  //     title: "Test 2",
  //     documents: [ { id: "132a8a5967e6e23ace1424b6" }, { id: "2e5bd9548336cbcb865a5884" }, { id: "325dda3213930d7a4158ce45" } ],
  //     desc: "Donec volutpat libero quis urna cursus facilisis non non ante. Donec pharetra lectus sed neque dignissim, ac ultricies eros sagittis",
  //     created_date: new Date(),
  //     created_by: this.navigatorService.getProfileId()
  //   },
  //   {
  //     id: this.navigatorService.generateKey(),
  //     title: "Test 1",
  //     documents: [ { id: "90b467648ae393bc5701bb3b" }, { id: "5252b3f84f4c910d26463483" }, { id: "58d0e502a4f3a8da8e9e433e" }, { id: "64e57734e69b5e2b3cae6f31" }, { id: "6c010e21c10444f1b0d42b3c" }, { id: "6e0cc1252eec2202a7d0912d" }, { id: "132a8a5967e6e23ace1424b6" }, { id: "2e5bd9548336cbcb865a5884" }, { id: "325dda3213930d7a4158ce45" } ],
  //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //     created_date: new Date(),
  //     created_by: this.navigatorService.getProfileId()
  //   },
  //   {
  //     id: this.navigatorService.generateKey(),
  //     title: "Test 3",
  //     documents: [ { id: "58d0e502a4f3a8da8e9e433e" }, { id: "64e57734e69b5e2b3cae6f31" }, { id: "6c010e21c10444f1b0d42b3c" }, { id: "6e0cc1252eec2202a7d0912d" } ],
  //     desc: "Suspendisse malesuada tincidunt sem",
  //     created_date: new Date(),
  //     created_by: this.navigatorService.getProfileId()
  //   }
  // ];

  public dealDocuments: Array<DocumentedDoc> = [ ];

  public statusChangeTag: AutomationTag = null;
  public statusChangeDesc: string = "";
  public statusChangeCustMessage: string = "";

  public focusImg: any = null;
  public focusDocument: any = null;
  public focusDealDocument: any = null;

  public uploadedJobImages: any = [];
  public uploadedDocuments: any = [];

  public detailFocus: number = this.detailTypes.DESCRIPTION;
  public documentTrailFocus: number = this.documentTypes.IMGS;

  public lat: number = 32.7767;
  public lng: number = -96.7970;
  public zoom: number = 10; 
  public mapStyles: any;

  public pageId: number = this.auditPages.STATUS;
  public statusPercentage: number = 0;

  private textMessage: string = "";
  private teamMessage: string = "";
  
  public editPriceMode: boolean = false;
  public editCloseDateMode: boolean = false;

  public customFields: Array<WorkOrderCustomField> = [];


  constructor(
    public dialog: MatDialog, 
    public snackBar: MatSnackBar, 
    public navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService, 
    private auditService: WorkOrderAuditService,
    private paymentHandler: PaymentHandlerService,
    private twilioHandler: TwilioHandlerService,
    private notificationService: NotificationService,
    private workOrdersService: WorkOrdersService,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    
    this.companyName = this.navigatorService.getCompanyName();

    console.log("Work Order Audit View: ", this.workOrder);

    this.activatedRoute.params.subscribe(params => { 
      this.companyId = params['company_id']; 
      this.workOrderId = params['work_order_id'];

      this.navigatorService.getWorkOrderAsForeign(this.companyId, this.workOrderId).then( (workOrder: WorkOrder) => {
        console.log("Foreign Find: ", workOrder);

        if(workOrder != undefined && workOrder != null) {
          this.workOrder = workOrder;
          this.initialize();
        }

      });

      
    });

    if(this.workOrder != undefined && this.workOrder != null) {
      this.customerViewer = false;
    }

    await this.initialize();
    this.getStatusTags();
    this.getWorkOrderTags();
    this.getGroups();

    if(!this.customerViewer && this.workOrder.dealId != undefined && this.workOrder.dealId != null) {
      this.getDeal(this.workOrder.dealId);
    }

    this.mapStyles = this.navigatorService.getMapStyles();
  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy() {

  }  

  private async initialize(): Promise<void> {
    if(this.workOrder != undefined && this.workOrder != null) {

      this.customFields = await this.getCustomFields();
      this.cleanMapAddress();
      this.getAssignedStaff();
      this.getAssignedTeams();

      if(this.workOrder?.documentedImgTrail == undefined || this.workOrder?.documentedImgTrail == null) {
        this.workOrder.documentedImgTrail = [];
      }

      if(this.workOrder?.documentedDocTrail == undefined || this.workOrder?.documentedDocTrail == null) {
        this.workOrder.documentedDocTrail = [];
      }

      if(this.workOrder?.expectedCloseDate == undefined || this.workOrder?.expectedCloseDate == null) {
        this.workOrder.expectedCloseDate = this.workOrder?.endDate;
      }

      this.jobImages = this.workOrder?.documentedImgTrail;
      this.documents = this.workOrder?.documentedDocTrail;

      this.clearImgObjects();
      this.clearFileObjects();

      this.auditService.initialize(this.workOrder.companyId).then( data => {

        this.companyProfile = this.auditService.getCompanyProfile();

        // Used for upgrading data to support features in platform build 2+ as well as ensure data integrity
        if(this.workOrder.auditTrail == undefined && this.workOrder.auditTrail == null) {
          this.initializeNewAuditTrail();
        } else {
          this.auditTrail = this.auditService.messageFormatLog(this.workOrder.auditTrail);
        }

        if(this.workOrder.latLng != undefined && this.workOrder.latLng != null) {
          this.lat = this.workOrder.latLng.lat;
          this.lng = this.workOrder.latLng.lng;

          this.zoom = 15;
        }

        if(this.workOrder.status == this.jobStatusTypes.COMPLETED || this.workOrder.status == this.jobStatusTypes.CLOSED || this.workOrder.status == this.jobStatusTypes.CANCELED) {
          this.statusPercentage = 99;
        }

        else if(this.workOrder.status == this.jobStatusTypes.ENROUTE || this.workOrder.status == this.jobStatusTypes.STARTED) {
          this.statusPercentage = 66;
        }

        else if(this.workOrder.dateScheduled != undefined && this.workOrder.dateScheduled != null) {
          this.statusPercentage = 33;
        }

      });

      this.getTag(this.workOrder.statusTag?.tag);

      this.dateScheduled = this.workOrder?.dateScheduled;
      this.endDateScheduled = this.workOrder?.endDate;
      this.emptyStart = this.workOrder?.dateScheduled;
      this.emptyEnd = this.workOrder?.endDate;

      this.setStartTime();
      this.setEndTime();
      this.updateMinutesRequired();
      

    }
  
  }

  private async getCustomFields(): Promise<Array<WorkOrderCustomField>> {
    this.customFields = await this.workOrdersService.getCustomFields();
    return this.customFields;
  }

  private cleanMapAddress(): void {

    let address: string = 
      this.workOrder.custAddress.street + ',' + 
      this.workOrder.custAddress.city + ',' + 
      this.workOrder.custAddress.state + ',' + 
      this.workOrder.custAddress.zip

    address = address.replace(/ /g, '%20');


    this.mapAddress = 'https://www.google.com/maps/embed/v1/place?key=' + environment.googleMapEmbeddedAPI + '&q=' + address;
  }

  groupSelectionChange(group: GroupPermissions): void {
    this.focusTeamLookup = group.id;
  }

  getSearchEmployees(): void {

  }

  getEmployeeProfileImgs(): void {

  }

  public changeTimePicker(picker: number): void {

    if(this.timePickerFocus != picker) {
      this.timePickerFocus = picker;
    }

  }

  stringTimeToDate(time: string, date: Date): Date {

    if(
      date == undefined || 
      date == null) {
      return null;
    }

    if(
      time == undefined || 
      time == null) {

        time = "";

    }

    let reconstructedDate: Date = date;

    if(time.length > 0) {
      let parts, hours, minutes,
      timeReg = /(\d+)\:(\d+) (\w+)/;
      parts = time.match(timeReg);
      
      hours = /am/i.test(parts[3]) ?
          function(am) {return am < 12 ? am : 0}(parseInt(parts[1], 10)) :
          function(pm) {return pm < 12 ? pm + 12 : 12}(parseInt(parts[1], 10));
      
      minutes = parseInt(parts[2], 10);

      reconstructedDate.setHours(hours);
      reconstructedDate.setMinutes(minutes);
      
      console.log(time + ' => ' + reconstructedDate);
    }

    return reconstructedDate;
  }

  setStartTime(): void {

    let hours: number = this.dateScheduled.getHours();
    let minutes: number = this.dateScheduled.getMinutes();
    let ampm: string = "AM";

    if(hours > 12) {
      ampm = "PM";
      hours -= 12;
    }

    this.startTimeScheduled = hours + ":" + minutes + " " + ampm;
    console.log("Start Time: ", this.startTimeScheduled);

  }

  setEndTime(): void {

    let hours: number = this.endDateScheduled.getHours();
    let minutes: number = this.endDateScheduled.getMinutes();
    let ampm: string = "AM";

    if(hours > 12) {
      ampm = "PM";
      hours -= 12;
    }

    this.endTimeScheduled = hours + ":" + minutes + " " + ampm;
    console.log("End Time: ", this.endTimeScheduled);

  }

  updateScheduleDate(): void {

    if(this.workOrder.dateScheduled != undefined && !this.autoMinuteAdjusted) {
      let startDate: Date = new Date(this.workOrder.dateScheduled);

      this.dateScheduled = this.stringTimeToDate(this.startTimeScheduled, startDate);

      this.workOrder.dateScheduled = this.dateScheduled;

      console.log("Scheduled Date Updated:", this.workOrder.dateScheduled);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  updateEndDate(): void {

    if(this.workOrder.endDate != undefined && !this.autoMinuteAdjusted) {
      let endDate: Date = new Date(this.workOrder.endDate.setHours(0, 0, 0, 0));

      this.endDateScheduled = this.stringTimeToDate(this.endTimeScheduled, endDate);

      this.workOrder.endDate = this.endDateScheduled;
      this.workOrder.expectedCloseDate = this.endDateScheduled;

      console.log("End Date Updated:", this.workOrder.endDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  updateScheduleTime(scheduledTime): void {
    this.startTimeScheduled = scheduledTime;
    this.updateScheduleDate();

    console.log("Date Scheduled: ", this.dateScheduled);
  }

  updateEndTime(endTime): void {
    this.endTimeScheduled = endTime;
    this.updateEndDate();

    console.log("Date End: ", this.endTimeScheduled);
  }

  adjustMinutes(minutes: number) {
    const currentMinutes = this.timeStretch || 0;
    const newMinutes = currentMinutes + minutes;
    this.timeStretch = newMinutes >= 0 ? newMinutes : 0; // Prevent negative values
    this.minutesRequiredChange(newMinutes >= 0 ? newMinutes : 0); // Ensure positive value is passed to update function
  }

  minutesRequiredChange(minutes: number): void {
    
    this.timeStretch = minutes;
    console.log("Minutes Req. Update: ", minutes);

    this.autoUpdateEndDateByMinutes();

  }

  autoUpdateEndDateByMinutes(): void {
    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null) {
        this.dateScheduled = new Date();
    }

    if(
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        this.endDateScheduled = new Date();
    }


    let startMili: number = this.dateScheduled.getTime();
    let minutes: number = this.timeStretch;
    let minutesRegMili: number = (minutes * 1000) * 60;

    let diffMili: number = minutesRegMili + startMili;

    this.endDateScheduled.setTime(diffMili);

    console.log("Auto Date Start: ", this.dateScheduled); 
    console.log("Auto Date End: ", this.endDateScheduled);   

    this.autoMinuteAdjusted = true;
    this.workOrder.dateScheduled = this.dateScheduled;

    this.autoMinuteAdjusted = true;
    this.workOrder.endDate = this.endDateScheduled;
    this.workOrder.timeStretch = this.timeStretch;

    this.setEndTime();
  }

  updateMinutesRequired(): void {

    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null || 
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        return;
    }


    let startMili: number = this.dateScheduled.getTime();
    let endMili: number = this.endDateScheduled.getTime();

    let diffMili: number = endMili - startMili;

    let seconds: number = diffMili / 1000;
    let minutes: number = seconds / 60;

    this.timeStretch = minutes;
    this.workOrder.timeStretch = this.timeStretch;

    console.log("Minute Diff: ", minutes);
    this.updateWorkOrder();
  }

  async getAssignedStaff(): Promise<void> {
    if(this.workOrder?.assignedStaff == undefined || this.workOrder?.assignedStaff == null) {
      this.workOrder.assignedStaff = [];
    }

    if(this.workOrder?.technicianId != undefined && this.workOrder?.technicianId != null) {

      if(!this.customerViewer) {
        await this.navigatorService.getAsyncEmployeeById(this.workOrder.technicianId).then( (employee: Profile) => {

          if(employee != undefined && employee != null) {
            this.assignedStaff.push(employee);
          }

        });
      } else {
        await this.navigatorService.getAsyncEmployeeByIdAsForeign(this.workOrder.technicianId, this.companyId).then( (employee: Profile) => {

          if(employee != undefined && employee != null) {
            this.assignedStaff.push(employee);
          }

        });
      }

    }

    for(let profile of this.workOrder?.assignedStaff) {

      if(!this.customerViewer) {
        await this.navigatorService.getAsyncEmployeeById(profile).then( (employee: Profile) => {

          if(employee != undefined && employee != null) {
            this.assignedStaff.push(employee);
          }

        });
      } else {
        await this.navigatorService.getAsyncEmployeeByIdAsForeign(profile, this.companyId).then( (employee: Profile) => {

          if(employee != undefined && employee != null) {
            this.assignedStaff.push(employee);
          }

        });
      }

    }

    this.getAllProfileImgs();
  }

  async getAssignedTeams(): Promise<void> {

    this.assignedTeams = [];

    if(this.workOrder.assignedTeams == undefined) {
      this.workOrder.assignedTeams = [];
    }

    for(let teamId of this.workOrder.assignedTeams) {

      if(!this.customerViewer) {
        this.navigatorService.getGroupPermission(teamId).then( (team: GroupPermissions) => {

          if(team != undefined && team != null) {

            this.assignedTeams.push(team);

          }

        });
      } else {
        this.navigatorService.getGroupPermissionAsForeign(teamId, this.companyId).then( (team: GroupPermissions) => {

          if(team != undefined && team != null) {

            this.assignedTeams.push(team);

          }

        });
      }

    }

  }

  getGroups(): void {

    if(!this.customerViewer) {
      this.navigatorService.getAsyncGroups().then( groups => { 
        
        if(groups != undefined && groups != null) {

          this.groups = groups;

        }

      });
    }

  }

  public deleteTeam(id: string): void {

    console.log("Delete Team: ", this.focusTeamLookup);

    let teamIndex: number = this.workOrder.assignedTeams.findIndex( (team: string) => { 
      return team == id;
    });

    if(teamIndex > -1) {

      this.workOrder.assignedTeams.splice(teamIndex, 1);
      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('The Team Reduced!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          
          let teamIndex: number = this.assignedTeams.findIndex( (team: GroupPermissions) => { return team.id == id });

          if(teamIndex > -1) {
            this.assignedTeams.splice(teamIndex, 1);
          }

        } else {
          this.snackBar.open('There was trouble updating the work order, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        }

      });

    } else {

        this.snackBar.open('There was trouble locating team information, please try again later.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });

    }

  }

  public deleteEmployee(id: string): void {

    console.log("Delete Employee: ", this.employeeLookupText);

    let employeeIndex: number = this.workOrder.assignedStaff.findIndex( (profile: string) => { 
      return profile == id;
    });

    if(employeeIndex > -1) {
      this.filteredEmployeeProfiles = [];
      this.filteredEmployeeOptions = [];

      this.workOrder.assignedStaff.splice(employeeIndex, 1);
      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('The Team Reduced!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          
          let techIndex: number = this.assignedStaff.findIndex( (profile: Profile) => { return profile.id == id });

          if(techIndex > -1) {
            this.assignedStaff.splice(techIndex, 1);
          }

        } else {
          this.snackBar.open('There was trouble updating the work order, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        }

      });

    } else {
    
      if(this.workOrder.technicianId == id) {
        this.workOrder.technicianId = null;

        this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

          if(status) {
            this.snackBar.open('The Team Reduced!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

            let techIndex: number = this.assignedStaff.findIndex( (profile: Profile) => { return profile.id == id });

            if(techIndex > -1) {
              this.assignedStaff.splice(techIndex, 1);
            }

          } else {
            this.snackBar.open('There was trouble updating the work order, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
          }
  
        });

      } else {
        this.snackBar.open('There was trouble locating profile information, please try again later.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    }

  }

  public addTeam(): void {

    if(this.workOrder.assignedTeams == undefined) {
      this.workOrder.assignedTeams = [];
    }

    this.workOrder.assignedTeams.push(this.focusTeamLookup);

    this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

      if(status) {
        this.snackBar.open('The Team Expanded!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.getAssignedTeams();
      } else {
        this.snackBar.open('There was trouble updating the work order, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

      this.changeTeamViewerFocus(this.teamViews.ASSIGNED);
    });

  }

  public addEmployee(): void {

    console.log("Add Employee: ", this.employeeLookupText);

    let employee: Profile = this.filteredEmployeeProfiles.find( (profile: Profile) => { 
      let name: string = profile.first_name + ' ' + profile.last_name;
      return name.toLowerCase() == this.employeeLookupText.toLowerCase();
    });

    if(employee != undefined && employee != null) {
      this.filteredEmployeeProfiles = [];
      this.filteredEmployeeOptions = [];

      this.workOrder.assignedStaff.push(employee.id);
      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('The Team Expanded!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.assignedStaff.push(employee);
          this.getAllProfileImgs();
        } else {
          this.snackBar.open('There was trouble updating the work order, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        }

      });

      this.changeTeamViewerFocus(this.teamViews.ASSIGNED);
    } else {
      this.snackBar.open('There was trouble locating profile information. Please make sure the employee\'s name was spelled correctly!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public updateFilterText(filterText: string): void {
    this.employeeLookupText = filterText;
  }

  public filterEmployee(filterText: string, updateSearch: boolean = true): void {
    this.employeeLookupText = filterText;
    this.filteredEmployeeOptions = [];

    this.navigatorService.getPagedSearchProfiles(filterText, 0, 10).then( (filteredProfiles: Array<Profile>) => {
      if(updateSearch) {
        this.filteredEmployeeProfiles = [];
      }

      if(filteredProfiles != undefined && filteredProfiles != null) {
        
        if(updateSearch) {
          this.filteredEmployeeProfiles = filteredProfiles;
        }

        for(let company of filteredProfiles) {
          let name: string = company.first_name + ' ' + company.last_name;
          this.filteredEmployeeOptions.push(name);
        }

      }

    });

  }


  private clearFileObjects(): void {

    for(let docObj of this.documents) {

      for(let doc of docObj.documents) {
        doc.file = null;
      }

    }

  }

  private clearImgObjects(): void {

    for(let imgObj of this.jobImages) {

      for(let img of imgObj.imgs) {
        img.file = null;
      }

    }

  }

  initializeNewAuditTrail(): void {
    this.workOrder.auditTrail = [];

    this.workOrder.auditTrail.unshift({
      id: this.navigatorService.generateKey(this.keyTypes.AUDIT_LOG_ID),
      type: this.auditLogTypes.CREATED,
      data: {
        date: this.workOrder.originationDate,
        scheduledDate: this.workOrder.dateScheduled,
        status: "Initial"
      }
    });

    if(this.workOrder.dateScheduled != undefined && this.workOrder.dateScheduled != null) {
      this.workOrder.auditTrail.unshift({
        id: this.navigatorService.generateKey(this.keyTypes.AUDIT_LOG_ID),
        type: this.auditLogTypes.SCHEDULED,
        data: {
          date: this.workOrder.originationDate,
          scheduledDate: this.workOrder.dateScheduled,
          status: "Initial"
        }
      });
    }

    if(this.workOrder.technicianId != undefined && this.workOrder.technicianId != null) {
      this.workOrder.auditTrail.unshift({
        id: this.navigatorService.generateKey(this.keyTypes.AUDIT_LOG_ID),
        type: this.auditLogTypes.ASSIGNED,
        data: {
          date: this.workOrder.originationDate,
          scheduledDate: this.workOrder.dateScheduled,
          status: "Initial"
        }
      });
    }

    this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

      if(status) {
        this.auditTrail = this.auditService.messageFormatLog(this.workOrder.auditTrail);
      }

    });
  }

  private getWorkOrderTags(): void {
    this.automationTags = [];

    if(!this.customerViewer) {

      this.navigatorService.getAsyncAutomationTagsByType(this.tagTypes.WORK_ORDER).then( (tags: Array<AutomationTag>) => {

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'Priority',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#3277a8'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'Emergency',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#c77b18'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'Recurring',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#5fdb46'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'High Value',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#db4696'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'At Cost',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#a246db'
        });


        for(let tag of this.automationTags) {
          tag.color = this.getContrastColor(tag.background);
        }


      });

    } else {

      this.navigatorService.getAsyncAutomationTagsByTypeAsForeign(this.tagTypes.WORK_ORDER, this.companyId).then( (tags: Array<AutomationTag>) => {

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'Priority',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#3277a8'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'Emergency',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#c77b18'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'Recurring',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#5fdb46'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'High Value',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#db4696'
        });

        this.automationTags.push({
          id: this.navigatorService.generateKey(),
          companyId: this.navigatorService.getCompanyId(),
          title: 'At Cost',
          tagType: this.tagTypes.WO_STATUS,
          automation: undefined,
          border: '#2a2b2b',
          background: '#a246db'
        });


        for(let tag of this.automationTags) {
          tag.color = this.getContrastColor(tag.background);
        }


      });

    }

  }

  private getStatusTags(): void {
    this.statusTags = [];

    if(!this.customerViewer) {
      this.navigatorService.getAsyncAutomationTagsByType(this.tagTypes.WO_STATUS).then( (tags: Array<AutomationTag>) => {

        this.statusTags = tags;

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Quote',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#3277a8'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Creating Permit',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#c77b18'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Break',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#5fdb46'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Delayed',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#db4696'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'One the way',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#a246db'
        // });


        // for(let tag of this.statusTags) {
        //   tag.color = this.getContrastColor(tag.background);
        // }


      });

    } else {

      this.navigatorService.getAsyncAutomationTagsByTypeAsForeign(this.tagTypes.WO_STATUS, this.companyId).then( (tags: Array<AutomationTag>) => {

        this.statusTags = tags;

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Quote',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#3277a8'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Creating Permit',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#c77b18'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Break',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#5fdb46'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'Delayed',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#db4696'
        // });

        // this.statusTags.push({
        //   id: this.navigatorService.generateKey(),
        //   companyId: this.navigatorService.getCompanyId(),
        //   title: 'One the way',
        //   tagType: this.tagTypes.WO_STATUS,
        //   automation: undefined,
        //   border: '#2a2b2b',
        //   background: '#a246db'
        // });


        // for(let tag of this.statusTags) {
        //   tag.color = this.getContrastColor(tag.background);
        // }


      });

    }

  }

  private getTag(id: string): void {

    if(id != undefined && id != null) {

      if(!this.customerViewer) {
        this.navigatorService.getAsyncAutomationTag(id).then( (tag: AutomationTag) => {

          if(tag != undefined && tag != null) {
            this.statusTag = tag;
          }

        });
      } else {
        
        this.navigatorService.getAsyncAutomationTagAsForeign(id, this.companyId).then( (tag: AutomationTag) => {

          if(tag != undefined && tag != null) {
            this.statusTag = tag;
          }

        });

      }
        
    }

  }

  private getDeal(id: string): void {

    this.navigatorService.getAsyncCRMDeal(id).then( (deal: CRMDeal) => {

      if(deal != null && deal != undefined) {
        this.deal = deal;
      }
      
    });

  }

  private download(file: FileObject) {
              
    //creating an invisible element
    var element = document.createElement('a');
    element.setAttribute('href', file?.file?.changingThisBreaksApplicationSecurity);
    element.setAttribute('download', file.title);

    document.body.appendChild(element);

    //onClick property
    element.click();

    document.body.removeChild(element);
  }

  // Needs adjustments to put objects in correct array
  public getDocument(doc: FileObject): void {
    // let upload: DocumentedDoc = this.documents.find( (uploadContainer: DocumentedDoc) => { return uploadContainer.id == uploadContainerId });
    // let doc: DocumentedDoc = this.documents.find( (document: DocumentedDoc) => { return document.id == docId });

    if(doc != undefined) {

      this.imageHandler.getAsyncFile(doc?.id).then( (file) => {

        console.log("File Found: ", file);
        doc.file = file;
        this.download(doc);

      });

    }

  }

  // Needs adjustments to put objects in correct array
  public getAllDocuments(): void {
    let docIds: Array<string> = [];

    for(let doc of this.documents[this.documentAuditFocus].documents) {
      docIds.push(doc.id);
    }

    this.imageHandler.getAsyncFiles(docIds).then( (docs: Array<any>) => {

      if(docs) {
        let index: number = 0;

        for(let doc of this.documents[this.documentAuditFocus].documents) {
          doc.file = docs[index];
          index++;
        }

      }

      console.log("Focused Job Documents: ", this.documents[this.documentAuditFocus]);

    });
  }

  public getAllProfileImgs(): void {
    let imgIds: Array<string> = [];
    let folder: string = "";

    for(let profile of this.assignedStaff) {

      if(profile.profileImg == undefined) {

        profile.profileImg = {
          id: null,
          file: null
        }

      }

      profile.profileImg.id = profile.image;
      imgIds.push(profile.image);
    }

    if(!this.customerViewer) {
      folder = this.navigatorService.getCompanyId();
    } else {
      folder = this.companyId;
    }


    this.imageHandler.getAsyncFiles(imgIds, folder).then( (imgs: Array<any>) => {

      if(imgs) {
        let index: number = 0;

        for(let profile of this.assignedStaff) {
          profile.profileImg.file = imgs[index];
          index++;
        }

      }

    });
  }

  public getImg(imgIndex: number): void {
    if(this.jobAuditFocus[imgIndex].imgs[imgIndex] != undefined) {
      this.imageHandler.getFile(this.jobAuditFocus[imgIndex].imgs[imgIndex].id);
    }
  }

  public getAllImgs(): void {
    let imgIds: Array<string> = [];
    let folder: string = "";

    for(let img of this.jobImages[this.jobAuditFocus].imgs) {
      imgIds.push(img.id);
    }

    if(!this.customerViewer) {
      folder = this.navigatorService.getCompanyId();
    } else {
      folder = this.companyId;
    }

    this.imageHandler.getAsyncFiles(imgIds, folder).then( (imgs: Array<any>) => {
      // this.gallery[]

      if(imgs) {
        let index: number = 0;

        for(let img of this.jobImages[this.jobAuditFocus].imgs) {
          img.file = imgs[index];
          index++;
        }

      }

      console.log("Focused Job Images: ", this.jobImages[this.jobAuditFocus]);

    });
  }

  public uploadImages(images: Array<any>): Array<FileObject> {

    let imgIds: Array<FileObject> = [];

    if(images != null && images != undefined && images.length > 0) {

      for(let imageIndex: number = 0; imageIndex < images.length; imageIndex++) {
        let file: File = images[imageIndex].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        imgIds.push( { 
          id: imgIdUnique,
          title: file.name
        } );

        if(imageIndex == images.length) {
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
            // Last Image Uploaded
          });
        } else {
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

          });
        }

      }
    }

    return imgIds;
  }

  // public deleteImg(imgIndex: number): void {
  //   this.images.splice(imgIndex, 1);
  //   this.imgIds.splice(imgIndex, 1);
  //   this.imageDeleteAction = true;
  // }



  public uploadJobImages(desc: string): void {
    console.log("Img desc: ", desc);
    console.log("Img Upload: ", this.uploadedJobImages);

    let imgIds: Array<FileObject> = this.uploadImages(this.uploadedJobImages);

    this.jobImages.unshift({
      id: this.navigatorService.generateKey(),
      imgs: imgIds,
      desc: desc,
      created_date: new Date(),
      created_by: this.navigatorService.getProfileId()
    });

    this.workOrder.documentedImgTrail = [...this.jobImages];

    this.uploadedJobImages = [];

    if(!this.customerViewer) {

      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

      });

    } else {

      this.navigatorService.updateWorkOrderAsForeign(this.workOrder).then( (status: boolean) => {

      });

    }

  }

  public uploadDocuments(desc: string): void {
    console.log("Document desc: ", desc);
    console.log("Document Upload: ", this.uploadedDocuments);

    let docIds: Array<FileObject> = this.uploadImages(this.uploadedDocuments);

    this.documents.unshift({
      id: this.navigatorService.generateKey(),
      title: '',
      documents: docIds,
      desc: desc,
      created_date: new Date(),
      created_by: this.navigatorService.getProfileId()
    });

    this.workOrder.documentedDocTrail = [...this.documents];

    this.uploadedDocuments = [];

    if(!this.customerViewer) {

      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

      });

    } else {

      this.navigatorService.updateWorkOrderAsForeign(this.workOrder).then( (status: boolean) => {

      });

    }

  }

  public changePage(pageId: number): void {
    let self = this;
    this.pageId = pageId;

    switch(this.pageId) {

      case this.auditPages.MESSAGING:
        setTimeout(function() {
          self.messageHistoryContainerRef.nativeElement.scrollTop = self.messageHistoryContainerRef.nativeElement.scrollHeight;
        }, 100);
        break;
      
      case this.auditPages.TEAM_CHAT:
        setTimeout(function() {
          self.messageHistoryContainerRef.nativeElement.scrollTop = self.messageHistoryContainerRef.nativeElement.scrollHeight;
        }, 100);
        break;
    }

  }

  public changeDetailFocus(focus: number): void {
    this.detailFocus = focus;
  }

  public changeDocumentTrailFocus(focus: number): void {
    this.documentTrailFocus = focus;
  }

  public changeJobImageFocus(id: string): void {
    let jobIndex: number = this.jobImages.findIndex( (element: DocumentedImage) => { return element.id == id });

    if(jobIndex > -1) {
      this.jobAuditFocus = jobIndex;
      this.focusImg = null;

      if(this.jobImages[jobIndex].imgs.length > 0 && this.jobImages[jobIndex].imgs[0].file == null) {
        this.getAllImgs();
      }
    }

  }

  public changeFocusImg(auditId: string, img: any): void {
    this.changeJobImageFocus(auditId);
    this.focusImg = img;
  }

  public minimizeFocusImg(): void {
    this.focusImg = null;
  }

  public expectedCloseDateChanged(): void {
    let self = this;

    this.navigatorService.getWorkOrder(this.workOrder.workOrderId).then( (wo: WorkOrder) => {

      wo.expectedCloseDate = self.workOrder.expectedCloseDate;

      self.navigatorService.updateWorkOrder(wo).then( (status: boolean) => {

        if(status) {
          // Do Nothing
        }

      });

    });

  }

  public updateWorkOrderBudget(): void {

    this.openProjectBudgetDialog();

  }

  public readAllMode(): void {
    this.priceReadMode();
    this.closeDateReadMode()
  }

  public priceEditable(): void {
    this.editPriceMode = true;
  }

  public priceReadMode(): void {
    this.editPriceMode = false;
  }

  public closeDateEditable(): void {
    this.editCloseDateMode = true;
  }

  public closeDateReadMode(): void {
    this.editCloseDateMode = false;
  }

  public changeTeamViewerFocus(focus: number): void {
    this.teamFocusView = focus;

    this.collapseAddTeamPanel();
  }

  public collapseStatusPanel(): void {
    this.statusChangeTag = null;
    this.statusTagsPanelExpand = false;
  }

  public expandAddTeamPanel(): void {
    this.addStaffPanel = true;
  }

  public collapseAddTeamPanel(): void {
    this.addStaffPanel = false;
  }

  public expandStatusPanel(): void {
    this.statusTagsPanelExpand = true;
  }

  public collapseTagsPanel(): void {
    this.tagsPanelExpand = false;
  }

  public expandTagsPanel(): void {
    this.tagsPanelExpand = true;
  }

  public changeStatus(tag: AutomationTag): void {
    this.statusChangeTag = tag;
  }

  public saveStatusChange(desc: string, message: string): void {
    this.statusChangeDesc = desc;
    this.statusChangeCustMessage = message;

    if(this.statusChangeTag != null) {
      this.statusTag = this.statusChangeTag;

      let status: StatusTag = {
        tag: this.statusTag.id,
        desc:this.statusChangeDesc,
        message: this.statusChangeCustMessage
      };

      this.workOrder.statusTag = status;
      this.collapseStatusPanel();

      // export enum notificationCategoriesEnum {
      //   DISPATCH = 0,
      //   WO_SCHEDULING = 1,
      //   BILLING = 2,
      //   SHIFT_SCHEDULING = 3,
      //   CMMS = 4,
      //   INVENTORY_MANAGEMENT = 5,
      //   ADMIN = 6,
      //   FIELD_OPERATOR = 7,
      //   MESSAGING = 8
      // }
      
      // export enum notificationStatusEnum {
      //   GOOD = 0,
      //   WARNING = 1,
      //   BAD = 2,
      //   CRITICAL = 3,
      //   INFORMATIONAL = 4
      // }

      let notification: ApplicationNotificationMessage = {
        id: this.navigatorService.generateKey(),
        message: 'Work Order Status Updated',
        category: this.notificationCategories.DISPATCH,
        status: this.notificationStatus.INFORMATIONAL
      };

      // this.notificationService.addForeignNotification(this.navigatorService.getProfileId(), notification);

      this.navigatorService.updateWorkOrder(this.workOrder);

      // this.sendAutomatedStatusText();
    }

    
  }

  public addAutomationTag(tag: AutomationTag): void {

    let tagIndex: number = this.assignedAutomationTags.findIndex( (automationTag: AutomationTag) => { return automationTag.id == tag.id });

    if(tagIndex == -1) {
      if(this.workOrder.tags == undefined || this.workOrder.tags == null) {
        this.workOrder.tags = [];
      }

      this.workOrder.tags.push(tag.id);
      this.assignedAutomationTags.push(tag);

      // this.navigatorService.updateWorkOrder(this.workOrder);
    }
    
  }

  public removeAutomationTag(tag: AutomationTag): void {
    let tagIndex: number = this.assignedAutomationTags.findIndex( (automationTag: AutomationTag) => { return automationTag.id == tag.id });

    if(tagIndex > -1) {

      this.workOrder.tags.splice(tagIndex, 1);
      this.assignedAutomationTags.splice(tagIndex, 1);

      // this.navigatorService.updateWorkOrder(this.workOrder);
    }
  }

  public activateNotificationMethod(method: number): void {

    switch(method) {

      case this.notificationMethods.SMS:

        if(this.workOrder.allowSMS) {
          this.workOrder.allowSMS = false;
        } else {
          this.workOrder.allowSMS = true;
        }

        break;

      case this.notificationMethods.EMAIL:

        if(this.workOrder.allowEmail) {
          this.workOrder.allowEmail = false;
        } else {
          this.workOrder.allowEmail = true;
        }

        break;

    }

    if(!this.customerViewer) {
      this.navigatorService.updateWorkOrder(this.workOrder);
    } else {
      this.navigatorService.updateWorkOrderAsForeign(this.workOrder);
    }

  }

  public openInvoice(invoice: WOInvoice): void {
    pdfMake.createPdf(invoice.invoice).open();
  }

  public makePayment(paymentDetails: StripePaymentDetails): void {

    if(paymentDetails != null && paymentDetails != undefined) {
     
      this.paymentHandler.makeStripePayment(paymentDetails);
      
    } else {
      this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public makePaymentAsForeign(paymentDetails: StripePaymentDetails): void {

    if(paymentDetails != null && paymentDetails != undefined) {
     
      this.paymentHandler.makeStripePaymentAsForeign(this.companyId, paymentDetails);
      
    } else {
      this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public updateTextMessage(message: string): void {
    this.textMessage = message;
  }

  public updateTeamMessage(message: string): void {
    this.teamMessage = message;
  }

  public async sendTeamMessage(): Promise<void> {

    if(this.teamMessage.length > 0) {

      if(this.workOrder.teamMessages == undefined || this.workOrder.teamMessages == null) {
        this.workOrder.teamMessages = [];
      }

      this.workOrder.teamMessages.push({
        id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
        senderId: this.navigatorService.getProfileId(),
        message: this.teamMessage,
        date: new Date()
      });

      this.navigatorService.getWorkOrder(this.workOrder.workOrderId).then( async (workOrder: WorkOrder) => {

        if(workOrder != undefined && workOrder != null) {

          workOrder.teamMessages = this.workOrder.teamMessages;

          await this.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {

            if(status) {
              this.teamMessage = null;
            }

          });

        }

      });

      this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;

    }

  }

  public async sendMessage(): Promise<void> {

    if(this.textMessage.length > 0) {

      if(this.workOrder.b2cMessages == undefined || this.workOrder.b2cMessages == null) {
        this.workOrder.b2cMessages = [];
      }

      this.workOrder.b2cMessages.push({
        id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
        senderId: this.navigatorService.getProfileId(),
        message: this.textMessage,
        date: new Date()
      });

      if(!this.customerViewer) {
        this.navigatorService.getWorkOrder(this.workOrder.workOrderId).then( async (workOrder: WorkOrder) => {

          if(workOrder != undefined && workOrder != null) {

            workOrder.b2cMessages = this.workOrder.b2cMessages;

            await this.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {

              if(status && workOrder.allowSMS) {
                this.twilioHandler.sendTextMessage(this.textMessage, workOrder.custPhone);
              }

            });

            this.textMessage = null;

          }

        });
      } else {
        this.navigatorService.getWorkOrderAsForeign(this.companyId, this.workOrderId).then( async (workOrder: WorkOrder) => {

          if(workOrder != undefined && workOrder != null) {

            workOrder.b2cMessages = this.workOrder.b2cMessages;

            await this.navigatorService.updateWorkOrderAsForeign(workOrder).then( (status: boolean) => {

              this.textMessage = null;

            });

          }

        });
      }

      this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;

    }

  }

  onMapReady(map?: google.maps.Map ){
    if(map)
      map.setOptions({
        streetViewControl: false
      });
  }

  private getContrastColor(hexcolor: string): string {

    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
  
    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
  
    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
  
    // Get YIQ ratio
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
    // Check contrast
    return (yiq >= 128) ? '#000000' : '#ffffff';
  
  }

  private updateWorkOrder(): void {
    let self = this;

    if(!this.customerViewer) {
      this.navigatorService.updateWorkOrder(this.workOrder).then( (status: boolean) => {

        if(status) {
          self.refreshWorkOrder();
        }

      });
    } else {
      this.updateForeignWorkOrder()
    }

  }

  private updateForeignWorkOrder(): void {

    this.navigatorService.getWorkOrderAsForeign(this.companyId, this.workOrderId).then( async (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {

        workOrder.b2cMessages = this.workOrder.b2cMessages;

        await this.navigatorService.updateWorkOrderAsForeign(workOrder).then( (status: boolean) => {

          // Do Something

        });

      }

    });

  }

  private refreshWorkOrder(): void {

    this.cleanMapAddress();

  }


  public summaryEditMode(): void {
    this.summaryEdit = true;
  }

  public summaryViewMode(): void {
    this.summaryEdit = false;
  }

  public summarySave(): void {
    this.summaryViewMode();

    this.updateWorkOrder();
  }

  public customerEditMode(): void {
    this.customerEdit = true;
  }

  public customerViewMode(): void {
    this.customerEdit = false;
  }

  public customFieldsEditMode(): void {
    this.customFieldsEdit = true;
  }

  public customFieldsViewMode(): void {
    this.customFieldsEdit = false;
  }

  public customerSave(): void {
    this.customerViewMode();
    
    this.updateWorkOrder();
  }


  public openWorkOrderDialog(workOrder?: WorkOrder) {
    
    if(workOrder != undefined && workOrder != null) {
      this.workOrder = workOrder;
    }

    if(this.workOrder != undefined && this.workOrder != null) {

      this.workOrderDialogRef = this.dialog.open(
        // VendorWorkOrderDialogComponent,
        VendorWorkOrderFormDialogComponent,
        { 
          data: {
            workOrder: this.workOrder.workOrderId
          }
        
        }
      );

      this.workOrderDialogRef.beforeClosed().subscribe(data => {
        console.log("Before Closed: ", data);
      })
      
      this.workOrderDialogRef.afterClosed().pipe(
        filter(data => data)
      ).subscribe(data => {
        console.log("Dialog Closed: ", data);

        this.navigatorService.updateWorkOrder(data);
        // this.files.push({ name, content: ''});
      })

    }
    
  }

  public openProjectBudgetDialog() {
    
    if(this.workOrder == undefined || this.workOrder == null) {
      this.snackBar.open('There was an error retrieving budget information, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.projectBudgetDialogRef = this.dialog.open(
      VendorProjectBudgetDialogComponent,
      { 
        data: {
          workOrder: this.workOrder.workOrderId
        }
      
      }
    );

    this.projectBudgetDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    })
    
    this.projectBudgetDialogRef.afterClosed().subscribe((budget: Budget) => {
      console.log("Dialog Closed: ", budget);

    })
    
  }

  public openWorkOrderQuickActionDialog(category: number): void {

    let quickAction: WorkOrderQuickAction = {
      category: category,
      workOrderId: this.workOrder?.workOrderId
    }

    this.workOrderQuickActionDialogRef = this.dialog.open(
      VendorWorkOrderQuickActionDialogComponent,
      { data: quickAction }
    );

    this.workOrderQuickActionDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    });
    
    this.workOrderQuickActionDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed: ", data);


      this.navigatorService.updateWorkOrder(data).then(incomingData => {

        

      });

    });

  }

}









/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  styles: [`
    .calendar-header {
      display: inline-flex;
      width: 80%;
      align-items: center;
      padding: 0.5em;
    }

    #timeContainer {
      display: inline-flex;
      width: 20%;
      justify-content: center;
    }

    .example-header-label {
      margin-top: -.8em;
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }

    .double-arrow {
      margin-left: -.6em
    }
  `],
  template: `
    <div class="calendar-header">
      <div role="button" class="example-double-arrow" (click)="previousClicked('year')">
        <span class="material-icons d-inline">chevron_left</span>
        <span class="material-icons d-inline double-arrow">chevron_left</span>
      </div>
      <div role="button" mat-icon-button (click)="previousClicked('month')">
        <span class="material-icons d-inline">chevron_left</span>
      </div>
      <span class="example-header-label">{{periodLabel}}</span>
      <div role="button" mat-icon-button (click)="nextClicked('month')">
        <span class="material-icons d-inline">chevron_right</span>
      </div>
      <div role="button" mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
        <span class="material-icons d-inline">chevron_right</span>
        <span class="material-icons d-inline double-arrow">chevron_right</span>
      </div>
    </div>

    <div role="button" id="timeContainer" (click)="openTimeClock()">
      <span class="material-icons">schedule</span>
    </div>                              
  `,
})

export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  public isTimeClockOpen: boolean = false;
  private timeElement: any = null;

  constructor(
      private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());

        this.timeElement = document.querySelector(".activeTimePicker");
        
        console.log("Calendar Element: ", this._calendar);
        console.log("Date Adapter Element: ", this._dateAdapter);
        console.log("Time Element: ", this.timeElement);
  }

  ngAfterViewInit() { 
    let test = document.querySelector(".calendar-header");

    console.log("Calendar Input Element: ", test);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
        .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  openTimeClock(): void {
    this.eventFire(this.timeElement, 'click');
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}